export const MuiAccordion = {
  styleOverrides: {
    root: {
      '&$expanded': {
        marginTop: 0,
      },
      '& .MuiAccordionDetails-root': {
        display: 'block',
      },
    },
    expanded: {},
  },
};

export const MuiAccordionSummary = ({ typography, palette }) => ({
  styleOverrides: {
    root: {
      background: palette.secondary.light,
      textTransform: 'uppercase',
      '&$expanded': {
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: 0,
      },
      '& .MuiTypography-body1': {
        fontWeight: typography.fontWeightMedium,
        color: palette.secondary,
      },
    },
    expanded: {},
  },
});
