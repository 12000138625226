import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import messages from '@esrh/app/src/messages';
import common from '@esrh/messages/src/main';
import { postLopetusilmoitus } from '@esrh/app/src/api';
import {
  arvioForm,
  arvioKeys,
  FormField,
  getOptions,
  koulutusForm,
  koulutusKeys,
  trueId,
  TyokykyField,
  tyokykyForm,
} from './util';
import {
  parseDate,
  useDateValidator,
  useBusinessIdValidator,
} from '@esrh/core/src/FormUtil/Validators';
import CustomAlert from '@esrh/core/src/Alert/CustomAlert';
import { SideBySideField } from '@esrh/core/src/FormUtil/Fields';
import { styled } from '@mui/material/styles';
import { selectStyle } from '../../FormUtil/Styles';

const getKeyValues = (data) => ({
  tyo: { key: 'tyollisyysTilanne', value: data.tyollisyystilanneLopetusList, required: true },
  koulutusOn: { key: 'koulutusTutkintoOn', value: data.koulutusOnLopetusList },
  koulutus: { key: 'koulutusTutkinto', value: data.koulutusLopetus, required: true },
  tyonantajaHr: {
    key: 'arvioTyonantajanHR',
    value: data.arvioTyonantajanHRList
  },
});

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  ...selectStyle(theme),
}));

const Basics = styled('div')(({ theme }) => ({
  '& .MuiFormControl-root': {
    marginTop: theme.spacing(1),
  },
}));
const Additional = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
const Arvio = styled(FormField)(({ theme }) => ({
  padding: '3px 0',
  '&:nth-of-type(even)': {
    background: theme.palette.action.hover,
  },
  '& label': {
    display: 'flex',
    flexWrap: 'wrap-reverse',
  },
  '& .MuiFormControlLabel-label': {
    flex: 8,
    minWidth: 400,
  },
  '& .MuiInput-formControl': {
    minWidth: 310,
  },
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: 'flex',
  justifyContent: 'space-between',
}));
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiFormGroup-row': {
    flex: 1,
    display: 'block',
    '& label:first-of-type': {
      marginRight: theme.spacing(3),
    },
  },
}));

export default function LopetusilmoitusForm({
  osallistujaId,
  aloitusDate,
  erityistavoite,
  data,
  onClose,
}) {
  const intl = useIntl();
  const [formState, { text, radio }] = useFormState({arvioTyonantajanHR: '0', koulutusTutkintoOn: '0'});
  const [openAlert, setOpenAlert] = useState(false);
  const keyValues = getKeyValues(data);
  const hasKoulutus = formState.values.koulutusTutkintoOn === trueId;
  const isHr = formState.values.arvioTyonantajanHR === trueId;
  const showTyokyky = tyokykyForm.includes(erityistavoite);
  const isKoulutus = koulutusForm.includes(erityistavoite);
  const isArvio = arvioForm.includes(erityistavoite);
  const arvioList = isKoulutus ? koulutusKeys : isArvio && arvioKeys;
  const arvioData = isKoulutus
    ? data.koulutusKysely
    : isArvio && data.arvioList;

  const defaultValues = ({ key, value, className, index, required }) => ({
    className: className || 'formControl',
    label: index
      ? `${index}. ${intl.formatMessage(messages[key])}`
      : intl.formatMessage(messages[key]),
    errors: formState.errors,
    options: getOptions(value),
    required: required
  });

  const businessIdValidator = useBusinessIdValidator();

  return (
    <StyledForm
      onSubmit={async (event) => {
        event.preventDefault();
        if (Object.values(formState.validity).some((val) => !val)) {
          setOpenAlert(true);
        } else {
          const lopetusPvm = parseDate(formState.values.lopetusPvm);
          const paluuHankkeeseenPvm = parseDate(
            formState.values.paluuHankkeeseenPvm
          );
          await postLopetusilmoitus({
            osallistujaId,
            ...formState.values,
            lopetusPvm,
            paluuHankkeeseenPvm,
          }).then((ilmoitus) => onClose({ ilmoitus }));
        }
      }}
    >
      <CustomAlert severity={'error'} isOpen={openAlert} setOpen={setOpenAlert}>
        <FormattedMessage {...messages.formError} />
      </CustomAlert>
      <Basics>
        <SideBySideField
          required
          errors={formState.errors}
          {...text({
            name: 'lopetusPvm',
            validate: useDateValidator({
              notInFuture: true,
              notBefore: aloitusDate,
            }),
            validateOnBlur: !formState.errors.lopetusPvm,
            reValidateMode: 'onChange',
          })}
        />
        <SideBySideField
          errors={formState.errors}
          {...text({
            name: 'paluuHankkeeseenPvm',
            validate: useDateValidator({
              notInFuture: true,
              notAfter: formState.values.lopetusPvm,
              notRequired: true,
            }),
            validateOnBlur: true,
            reValidateMode: 'onChange',
          })}
        />
        <FormField
          {...defaultValues(keyValues.tyo)}
          {...text(keyValues.tyo.key)}
        />
        <StyledFormControl>
          <FormLabel component="legend">
            <FormattedMessage {...messages[keyValues.koulutusOn.key]} />
          </FormLabel>
          <RadioGroup row>
            {keyValues.koulutusOn.value                          //Olen suorittanut tutkinnon tai saanut ammattipätevyyden radiot
              .sort((a, b) => a.codeOrder - b.codeOrder)
              .map(({ codeId, codeValue }) => (
                <FormControlLabel
                  key={codeId}
                  label={intl.formatMessage(messages[codeId])}
                  errors={formState.errors}
                  {...radio('koulutusTutkintoOn', codeValue)}
                  value={codeValue}
                  control={<Radio color="primary" />}
                />
              ))}
          </RadioGroup>
        </StyledFormControl>
        {hasKoulutus && (                                   //Suoritetun tutkinnon tai pätevyyden taso
          <FormField
            {...defaultValues(keyValues.koulutus)}
            {...text(keyValues.koulutus.key)}
          />
        )}
        {isArvio && (
          <StyledFormControl>
            <FormLabel component="legend">
              <FormattedMessage {...messages[keyValues.tyonantajaHr.key]} />
            </FormLabel>
            <RadioGroup row>
              {keyValues.tyonantajaHr.value
                .sort((a, b) => a.codeOrder - b.codeOrder)
                .map(({ codeId, codeValue }) => (
                  <FormControlLabel
                    key={codeId}
                    value={codeValue}
                    label={intl.formatMessage(messages[codeId])}
                    errors={formState.errors}
                    {...radio('arvioTyonantajanHR', codeValue)}
                    control={<Radio color="primary" />}
                  />
                ))}
            </RadioGroup>
          </StyledFormControl>
        )}

        {isHr && (                                    //Jos vastattu kyllä Olen organisaation vastuuhenkilö/hr-vastaava
          <>
            <SideBySideField
              required
              errors={formState.errors}
              {...text('arvioTyonantajanNimi')}
            />
            <SideBySideField
              required
              errors={formState.errors}
              {...text({
                name: 'arvioTyonantajanTunnus',
                validate: businessIdValidator,
                validateOnBlur: !formState.errors.arvioTyonantajanTunnus,
                reValidateMode: 'onChange',
              })}
            />
          </>
        )}
      </Basics>
      <Additional>
        {arvioList &&
          arvioList.map((arvioKey, index) => (
            <FormField
              key={arvioKey + '_' + index}
              {...defaultValues({
                key: arvioKey,
                value: arvioData,
                component: Arvio,
                index: index + 1,
                required: isHr || isKoulutus,
              })}
              {...text(arvioKey)}
            />
          ))}
        {showTyokyky && (
          <TyokykyField
            placeholder={intl.formatMessage(messages.notSelected)}
            label={intl.formatMessage(messages.tyokyky)}
            className={'formControl'}
            {...text('tyokyky')}
          />
        )}
      </Additional>
      <ButtonContainer>
        <Button variant="contained" color="primary" type={'submit'}>
          <FormattedMessage {...common.submit} />
        </Button>
        <Button
          onClick={onClose}
          className={'submit'}
          variant="contained"
          color="primary"
        >
          <FormattedMessage {...common.cancel} />
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
  
}
