import React, { useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { getOsallistuja, useEsrhCodes, useHankeById } from 'api';
import { Button } from '@mui/material';
import {
  ActionPanel,
  HankePanel,
  IlmoitusPanel,
  PANEL,
} from '@esrh/core/src/ESRH/Accordion';
import Page from '../../../layouts/Page';
import { path as frontPage } from '../Frontpage';
import WithTimer from '@esrh/core/src/Alert/WithTimer';
import { getUserWithHetu } from '@esrh/core/src/ESRH/User/utils';
import { path as parent } from '..';
import { styled } from '@mui/material/styles';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Error from './Error';

export const path = `${parent}/hanke/:hankeId`;
export const exact = true;

const LinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3),
  '& .MuiButton-textSecondary:hover': {
    background: 'transparent',
  },
  '& .MuiButton-label': {
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const component = function Hanke() {
  const {
    params: { hankeId },
  } = useRouteMatch();
  const { state } = useLocation();
  const openUser = state && state.user;
  const hanke = useHankeById(hankeId);
  const esrhData = useEsrhCodes();
  const [expanded, setExpanded] = useState(
    openUser
      ? [PANEL.user, PANEL.aloitusilmoitus, PANEL.lopetusilmoitus]
      : [PANEL.info]
  );
  const [action, setAction] = useState(openUser ? PANEL.user : PANEL.info);
  const [user, setUser] = useState(openUser);

  const { trackEvent } = useMatomo();

  const trackEventToMatomo = (action) => {
    trackEvent({
      category: 'search',
      action: action,
    });
  };

  if (hanke.error || esrhData.error) {
    return (
      <Error
        error={
          <FormattedMessage
            {...messages[hanke.error ? 'noHanke' : 'serverError']}
          />
        }
      />
    );
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded ? expanded.concat(panel) : expanded.filter((p) => p !== panel)
    );
  };

  const handleActionButton = (action) => () => {
    trackEventToMatomo(action);
    setAction(action);
    const expand = expanded.includes(PANEL.info) ? [PANEL.info] : [];
    if (action === PANEL.user)
      setExpanded(
        expand.concat([PANEL.aloitusilmoitus, PANEL.lopetusilmoitus, action])
      );
    else setExpanded(expand.concat(action));
  };

  const getUser = (newUser) => {
    setUser(getUserWithHetu({ user: newUser }));
    handleActionButton(PANEL.user)();
  };

  const fetchUser = async (osallistujaId) => {
    const user = await getOsallistuja(osallistujaId);
    if (user.error) setExpanded(expanded.concat([PANEL.error]));
    else getUser(user);
  };

  return (
    <Page
      id={'accordion-wrapper'}
      topLeftContent={
        <Link to={frontPage}>
          <FormattedMessage defaultMessage={'Palaa etusivulle'} />
        </Link>
      }
    >
      <HankePanel
        id={PANEL.info}
        hanke={hanke}
        expanded={expanded}
        handleChange={handleChange}
      />
      <LinkWrapper id={'main-links'}>
        <Button color="secondary" onClick={handleActionButton(PANEL.search)}>
          <FormattedMessage {...messages.searchParticipants} />
        </Button>
        {user && (
          <Button color="secondary" onClick={handleActionButton(PANEL.user)}>
            <FormattedMessage {...messages.selectedUserInfo} />
          </Button>
        )}
      </LinkWrapper>
      <WithTimer
        severity={'warning'}
        isOpen={expanded.includes(PANEL.error)}
        setOpen={() => setExpanded(expanded.filter((p) => p !== PANEL.error))}
      >
        <FormattedMessage {...messages.tryAgain} />
      </WithTimer>
      {(action === PANEL.user || action === PANEL.search) && (
        <ActionPanel
          action={action}
          user={user}
          hankeId={hanke.hankeId}
          expanded={expanded}
          setUser={getUser}
          onOpen={fetchUser}
          handleChange={handleChange}
        />
      )}
      {action === PANEL.user && (
        <IlmoitusPanel
          idStart={PANEL.aloitusilmoitus}
          idEnd={PANEL.lopetusilmoitus}
          aloitusilmoitus={user.aloitusilmoitus}
          lopetusilmoitus={user.lopetusilmoitus}
          erityistavoite={hanke.erityistavoite}
          expanded={expanded}
          esrhData={esrhData}
          handleChange={handleChange}
          setAction={handleActionButton}
        />
      )}
    </Page>
  );
};
