import { createContext, useContext } from 'react';

const Context = createContext(({ children }) => children);

export function Layout({ FallbackComponent, ...other }) {
  return <Context.Provider value={FallbackComponent} {...other} />;
}

export default function LayoutFallback(props) {
  const Component = useContext(Context);
  return <Component {...props} />;
}
