import { FormattedMessage } from 'react-intl';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import messages from '@esrh/app/src/messages';
import {
  arvioForm,
  arvioKeys,
  koulutusForm,
  koulutusKeys,
  StyledCell,
  StyledTable,
  tyokykyForm,
  useGetEndData,
} from './util';
import { useState } from 'react';
import { deleteLopetusilmoitus } from '@esrh/app/src/api';
import ConfirmDialog from '@esrh/core/src/ConfirmDialog';
import { styled } from '@mui/material/styles';

const InnerTable = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,
  '& table': {
    '& tr:first-of-type': {
      '& th, td': {
        paddingTop: theme.spacing(1),
      },
    },
  },
}));

const ArvioList = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    background: theme.palette.action.hover,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export default function Lopetusilmoitus({
  lopetusilmoitus,
  erityistavoite,
  data,
  canEdit,
  ...props
}) {
  const [confirmRemove, setConfirmRemove] = useState(false);
  const rows = useGetEndData(lopetusilmoitus, data);
  const showTyokyky = tyokykyForm.includes(erityistavoite);
  const arvioList = koulutusForm.includes(erityistavoite)
    ? koulutusKeys
    : arvioForm.includes(erityistavoite) && arvioKeys;

  const removeLopetusilmoitus = async () => {
    await deleteLopetusilmoitus(lopetusilmoitus).then((ilmoitus) => {
      setConfirmRemove(false);
      props.onDeleted({ ilmoitus });
    });
  };

  return (
    <>
      <ConfirmDialog
        content={'deleteLopetusilmoitus'}
        open={confirmRemove}
        onClose={() => setConfirmRemove(false)}
        onConfirm={removeLopetusilmoitus}
      />
      <Grid key={lopetusilmoitus.lopetusId} container spacing={3}>
        <Grid item xs={12} md={9}>
          <StyledTable>
            <Table aria-label="lopetus table">
              <colgroup>
                <col style={{ width: '75%' }} />
                <col />
              </colgroup>
              <TableBody>
                {canEdit && (
                  <TableRow key={`row_add`}>
                    <StyledCell colSpan={2} component="th" scope="row">
                      <Button
                        onClick={props.addLopetusilmoitus}
                        color="primary"
                        className={'as-link'}
                      >
                        <Typography>
                          <FormattedMessage {...messages.addLopetusilmoitus} />
                        </Typography>
                      </Button>
                    </StyledCell>
                  </TableRow>
                )}
                {rows.map((row, index) => (
                  <TableRow key={`row_${index}`}>
                    <StyledCell component="th" scope="row" size={'medium'}>
                      {row.label}
                    </StyledCell>
                    <StyledCell size={'small'}>{row.value}</StyledCell>
                  </TableRow>
                ))}
                {showTyokyky && (
                  <TableRow key={`row_tyokyky`}>
                    <StyledCell component="th" scope="row">
                      <FormattedMessage {...messages.tyokyky} />
                    </StyledCell>
                    <StyledCell>{lopetusilmoitus.tyokyky}</StyledCell>
                  </TableRow>
                )}
                {arvioList && (
                  <TableRow>
                    <InnerTable colSpan="2">
                      <Table>
                        <TableBody>
                          {arvioList.map((key, index) => (
                            <ArvioList key={`row_${index}`}>
                              <StyledCell component="th" scope="row">
                                <FormattedMessage {...messages[key]} />
                              </StyledCell>
                              <StyledCell>{lopetusilmoitus[key]}</StyledCell>
                            </ArvioList>
                          ))}
                        </TableBody>
                      </Table>
                    </InnerTable>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTable>
        </Grid>
        <Grid item xs={12} md={3} style={{ textAlign: 'right' }}>
          {canEdit && (
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => setConfirmRemove(true)}
            >
              <FormattedMessage defaultMessage={'Poista lopetusilmoitus'} />
            </StyledButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}
