import { defineMessages } from 'react-intl';

// App spesific common messages
export default defineMessages({
  back: { defaultMessage: 'Takaisin' },
  home: { defaultMessage: 'Etusivu' },
  yes: { defaultMessage: 'Kyllä' },
  no: { defaultMessage: 'Ei' },
  etunimi: { defaultMessage: 'Etunimi' },
  sukunimi: { defaultMessage: 'Sukunimi' },
  sposti: { defaultMessage: 'Sähköposti' },
  syntymaPaivaH: { defaultMessage: 'Syntymäpäivä (pp.kk.vvvv)' },
  syntymaPaiva: { defaultMessage: 'Syntymäpäivä' },
  hetu: { defaultMessage: 'Henkilötunnus' },
  hetuEnd: { defaultMessage: 'Henkilötunnuksen loppuosa' },
  katuosoite: { defaultMessage: 'Katuosoite' },
  postinumero: { defaultMessage: 'Postinumero' },
  postitoimipaikka: { defaultMessage: 'Postitoimipaikka' },
  puhelinnumero: { defaultMessage: 'Puhelinnumero' },
  sukupuoli: { defaultMessage: 'Sukupuoli' },
  unknown: { defaultMessage: 'Ei tiedossa' },
  male: { defaultMessage: 'Mies' },
  female: { defaultMessage: 'Nainen' },
  logged: { defaultMessage: 'Olet kirjautunut sisään {name}.' },
  continueTo: { defaultMessage: 'Jatka hankkeeseen' },
  searchParticipants: { defaultMessage: 'Hae osallistujia' },
  notSelected: { defaultMessage: 'Ei valittu' },
  active: { defaultMessage: 'Aktiivinen' },
  removed: { defaultMessage: 'Poistettu' },
  basicInfo: { defaultMessage: 'Perustiedot' },
  peruuta: { defaultMessage: 'Peruuta' },
  state: { defaultMessage: 'Tila' },
  tryAgain: {
    defaultMessage:
      'Ole hyvä ja yritä uudelleen. Jos virhe toistuu, ota yhteyttä järjestelmän ylläpitäjään',
  },
  aloitusilmoitus: { defaultMessage: 'Aloitusilmoitus' },
  lopetusilmoitus: { defaultMessage: 'Lopetusilmoitus' },
  selectedUserInfo: { defaultMessage: 'Valitun osallistujan tiedot' },
  edit: { defaultMessage: 'muokkaa' },
  hankeId: { defaultMessage: 'Hankekoodi' },
  hanketoteuttaja: { defaultMessage: 'Hankkeen toteuttaja' },
  hankeFinancier: { defaultMessage: 'Rahoittava viranomainen' },
  toimintalinja: { defaultMessage: 'Toimintalinja' },
  hankePriority: { defaultMessage: 'Investointiprioriteetti' },
  erityistavoite: { defaultMessage: 'Erityistavoite' },
  hankkeet_HVO: { defaultMessage: 'Muodosta raportti (.xlsx -muodossa)' },
  sfcreport_group: { defaultMessage: 'Hae SFC-raportti' },
  hanke_SFC: { defaultMessage: 'Hae hankekohtainen SFC-raportti' },
  osallistujan_tiedot: { defaultMessage: 'Osallistujan tiedot -raportti' },
  hanke_indikaattorit: { defaultMessage: 'Hankkeen indikaattorit -raportti' },
  hankkeen_osallistujat: { defaultMessage: 'Hankkeen osallistujat -raportti' },
  hankkeen_osallistujatI: { defaultMessage: '(vaillinainen)' },
  hankkeen_osallistujatC: { defaultMessage: '(valmis)' },
  startDate: { defaultMessage: 'Alkamispvm' },
  hankeState: { defaultMessage: 'Hankkeen tila' },
  hankeName: { defaultMessage: 'Hankkeen nimi' },
  hankeWithName: { defaultMessage: 'Hanke: {name}' },
  userInfoStart: { defaultMessage: 'Osallistujan tiedot aloitustilanteessa' },
  userInfoEnd: { defaultMessage: 'Osallistujan tiedot lopetustilanteessa' },
  addLopetusilmoitus: { defaultMessage: 'Lisää lopetusilmoitus' },
  aloitusPvm: {
    defaultMessage: 'Päivämäärä, jolloin osallistuja aloitti hankkeessa',
  },
  enterHankeId: { defaultMessage: 'Syötä hankekoodi' },
  formError: {
    defaultMessage:
      'Lomakkeella näyttäisi olevan virheellisiä tietoja. Ole hyvä ja tarkista lomake.',
  },
  successAlert: { defaultMessage: 'Pyyntösi on suoritettu onnistuneesti.' },
  lopetusPvm: { defaultMessage: 'Lopetuspäivämäärä' },
  paluuHankkeeseenPvm: { defaultMessage: 'Paluupäivämäärä' },
  incorrectDate: {
    defaultMessage:
      'Virheellinen päivämäärä. Päivämäärä tulee olla muodossa pp.kk.vvvv, esim. 20.10.1970.',
  },
  dateInFuture: {
    defaultMessage:
      'Virheellinen päivämäärä. Päivämäärä ei voi olla tulevaisuudessa.',
  },
  dateBefore: {
    defaultMessage:
      'Virheellinen päivämäärä. Päivämäärä ei voi olla ennen aloitusilmoituksen aloituspäivämäärää ({date}).',
  },
  dateBeforeHanke: {
    defaultMessage:
      'Virheellinen päivämäärä. Päivämäärä ei voi olla ennen hankeen aloituspäivämäärää ({date}).',
  },
  dateAfter: {
    defaultMessage:
      'Virheellinen päivämäärä. Paluupäivämäärä ei voi olla lopetuspäivämäärän ({date}) jälkeen.',
  },
  ammattiluokka_1: {
    defaultMessage: 'Johtajat, ylimmät virkamiehet ja järjestöjen johtajat',
  },
  ammattiluokka_10: {
    defaultMessage: 'Lukion ja peruskoulun yläluokkien opettajat',
  },
  ammattiluokka_11: {
    defaultMessage:
      'Peruskoulun alaluokkien opettajat ja lastentarhanopettajat',
  },
  noHanke: {
    defaultMessage: 'Hanketta ei löytynyt. Ole hyvä ja tarkista hankekoodi',
  },
  serverError: {
    defaultMessage: 'Palvelinvirhe. Ole hyvä ja yritä uudelleen.',
  },
  ammattiluokka_12: { defaultMessage: 'Muut opetusalan erityisasiantuntijat' },
  ammattiluokka_13: {
    defaultMessage: 'Liike-elämän ja hallinnon erityisasiantuntijat',
  },
  ammattiluokka_14: {
    defaultMessage: 'Tieto- ja viestintäteknologian erityisasiantuntijat',
  },
  ammattiluokka_15: {
    defaultMessage:
      'Lainopilliset, sosiaalialan ja kulttuurialan erityisasiantuntijat',
  },
  ammattiluokka_16: {
    defaultMessage: 'Luonnontieteiden ja tekniikan asiantuntijat',
  },
  ammattiluokka_17: { defaultMessage: 'Terveydenhuollon asiantuntijat' },
  ammattiluokka_18: {
    defaultMessage: 'Liike-elämän ja hallinnon asiantuntijat',
  },
  ammattiluokka_19: {
    defaultMessage:
      'Lainopilliset avustajat sekä sosiaali- ja kulttuurialan asiantuntijat',
  },
  ammattiluokka_2: {
    defaultMessage: 'Hallintojohtajat ja kaupalliset johtajat',
  },
  ammattiluokka_20: {
    defaultMessage: 'Informaatio- ja tietoliikenneteknologian asiantuntijat',
  },
  ammattiluokka_21: { defaultMessage: 'Toimistotyöntekijät' },
  ammattiluokka_22: { defaultMessage: 'Asiakaspalvelutyöntekijät' },
  ammattiluokka_23: {
    defaultMessage: 'Laskennan ja varastoinnin toimistotyöntekijät',
  },
  ammattiluokka_24: {
    defaultMessage: 'Muut toimisto- ja asiakaspalvelutyöntekijät',
  },
  ammattiluokka_25: { defaultMessage: 'Palvelutyöntekijät' },
  ammattiluokka_26: { defaultMessage: 'Myyjät, kauppiaat ym.' },
  ammattiluokka_27: {
    defaultMessage: 'Hoivapalvelun ja terveydenhuollon työntekijät',
  },
  ammattiluokka_28: { defaultMessage: 'Suojelu- ja vartiointityöntekijät' },
  ammattiluokka_29: { defaultMessage: 'Maanviljelijät, metsätyöntekijät ym.' },
  ammattiluokka_3: {
    defaultMessage:
      'Tuotantotoiminnan ja yhteiskunnan peruspalvelujen johtajat',
  },
  ammattiluokka_30: {
    defaultMessage: 'Rakennus-, korjaus- ja valmistustyöntekijät',
  },
  ammattiluokka_31: { defaultMessage: 'Prosessi- ja kuljetustyöntekijät' },
  ammattiluokka_32: { defaultMessage: 'Muut työntekijät' },
  ammattiluokka_4: {
    defaultMessage:
      'Hotelli- ja ravintola-alan, vähittäiskaupan ja muiden palvelualojen johtajat',
  },
  ammattiluokka_5: {
    defaultMessage: 'Luonnontieteiden ja tekniikan erityisasiantuntijat',
  },
  ammattiluokka_6: { defaultMessage: 'Terveydenhuollon erityisasiantuntijat' },
  ammattiluokka_8: { defaultMessage: 'Yliopisto ja korkeakouluopettajat' },
  ammattiluokka_9: { defaultMessage: 'Ammatillisen koulutuksen opettajat' },
  arvio_1: { defaultMessage: '0 = ei lainkaan' },
  arvio_2: { defaultMessage: '1' },
  arvio_3: { defaultMessage: '2' },
  arvio_4: { defaultMessage: '3' },
  arvio_5: { defaultMessage: '4' },
  arvio_6: { defaultMessage: '5 = erittäin paljon' },
  arvio_7: { defaultMessage: 'Ei relevantti hankkeen sisällön kannalta' },
  koulutusaste_aloitus_1: { defaultMessage: 'Alempi perusaste' },
  koulutusaste_aloitus_2: { defaultMessage: 'Ylempi perusaste' },
  koulutusaste_aloitus_3: { defaultMessage: 'Keskiaste' },
  koulutusaste_aloitus_4: {
    defaultMessage: 'Keskiaste, erikoisammattitutkinnot',
  },
  koulutusaste_aloitus_5: { defaultMessage: 'Alin korkea-aste' },
  koulutusaste_aloitus_6: { defaultMessage: 'Alempi korkeakouluaste' },
  koulutusaste_aloitus_7: { defaultMessage: 'Ylempi korkeakouluaste' },
  koulutusaste_aloitus_8: { defaultMessage: 'Tutkijakoulutusaste' },
  koulutusaste_aloitus_9: { defaultMessage: 'Koulutusaste tuntematon' },
  koulutus_kysely_1: { defaultMessage: '1 (täysin eri mieltä)' },
  koulutus_kysely_2: { defaultMessage: '2' },
  koulutus_kysely_3: { defaultMessage: '3' },
  koulutus_kysely_4: { defaultMessage: '4' },
  koulutus_kysely_5: { defaultMessage: '5 (täysin samaa mieltä)' },
  koulutus_kysely_6: {
    defaultMessage: 'Ei relevantti hankkeen sisällön kannalta',
  },
  koulutus_lopetus_1: { defaultMessage: 'Alempi perusaste' },
  koulutus_lopetus_2: { defaultMessage: 'Ylempi perusaste' },
  koulutus_lopetus_3: { defaultMessage: 'Keskiaste' },
  koulutus_lopetus_4: { defaultMessage: 'Keskiaste, erikoisammattitutkinnot' },
  koulutus_lopetus_5: { defaultMessage: 'Alin korkea-aste' },
  koulutus_lopetus_6: { defaultMessage: 'Alempi korkeakouluaste' },
  koulutus_lopetus_7: { defaultMessage: 'Ylempi korkeakouluaste' },
  koulutus_lopetus_8: { defaultMessage: 'Tutkijakoulutusaste' },
  koulutus_on_lopetus_1: { defaultMessage: 'Kyllä' },
  koulutus_on_lopetus_2: { defaultMessage: 'Ei' },
  muut_taustatekijat_aloitus_1: { defaultMessage: 'Kyllä' },
  muut_taustatekijat_aloitus_2: { defaultMessage: 'Ei' },
  muut_taustatekijat_aloitus_3: { defaultMessage: 'En halua vastata' },
  opintoohjaaja_aloitus_1: { defaultMessage: 'Kyllä' },
  opintoohjaaja_aloitus_2: { defaultMessage: 'Ei' },
  tilanne_aloitus_1: { defaultMessage: 'Kyllä' },
  tilanne_aloitus_2: { defaultMessage: 'Ei' },
  toimintalinja_1: { defaultMessage: '6.1' },
  toimintalinja_2: { defaultMessage: '7.1' },
  toimintalinja_3: { defaultMessage: '8.1' },
  toimintalinja_4: { defaultMessage: '9.1' },
  toimintalinja_5: { defaultMessage: '9.2' },
  toimintalinja_6: { defaultMessage: '10.1' },
  tyollisyystilanne_aloitus_1: { defaultMessage: 'Työttömänä alle 6 kk' },
  tyollisyystilanne_aloitus_2: { defaultMessage: 'Työttömänä 6-12 kk' },
  tyollisyystilanne_aloitus_3: { defaultMessage: 'Työttömänä yli 12 kk' },
  tyollisyystilanne_aloitus_4: { defaultMessage: 'Työelämän ulkopuolella' },
  tyollisyystilanne_aloitus_5: {
    defaultMessage: 'Opiskelemassa/koulutuksessa',
  },
  tyollisyystilanne_aloitus_6: { defaultMessage: 'Työssä' },
  tyollisyystilanne_aloitus_7: {
    defaultMessage: 'Yrittäjänä tai itsenäisenä ammatinharj.',
  },
  tyollisyystilanne_lopetus_1: { defaultMessage: 'Työttömänä' },
  tyollisyystilanne_lopetus_2: { defaultMessage: 'Ryhtynyt työnhakuun' },
  tyollisyystilanne_lopetus_3: {
    defaultMessage: 'Opiskelemassa/koulutuksessa',
  },
  tyollisyystilanne_lopetus_4: { defaultMessage: 'Työelämän ulkopuolella' },
  tyollisyystilanne_lopetus_5: {
    defaultMessage: 'Työssä (ml. oppisopimuskoulutus)',
  },
  tyollisyystilanne_lopetus_6: {
    defaultMessage: 'Yrittäjänä tai itsenäisenä ammatinharj.',
  },
  tyonantaja_1: { defaultMessage: 'Kunta' },
  tyonantaja_2: { defaultMessage: 'Muu julkinen taho' },
  tyollisyysTilanne: { defaultMessage: 'Työllisyystilanne' },
  tyonantaja: { defaultMessage: 'Työnantaja' },
  ammattiluokka: { defaultMessage: 'Ammattiluokka' },
  ohjauspalvelunAsiantuntija: {
    defaultMessage:
      'Opinto-ohjaaja, uraneuvoja tai muu ohjauspalvelun asiantuntija',
  },
  koulutusTaso: { defaultMessage: 'Koulutusasteeni' },
  muutUlkomaalainen: {
    defaultMessage:
      'Olen ulkomaalaistaustainen tai kuulun johonkin vähemmistöön',
  },
  muutVammainen: {
    defaultMessage: 'Minulla on vammaisetuuteen oikeuttava tai muu vamma',
  },
  muutHeikkous: {
    defaultMessage:
      'Olen jostain muusta syystä heikommassa asemassa työmarkkinoilla',
  },
  tyokyky: {
    defaultMessage:
      'Oletetaan, että työkykysi on parhaimmillaan saanut arvosanaksi 10. Minkä arvosanan antaisit nykyiselle työkyvyllesi asteikolla 0-10? Nolla (0) tarkoittaa ettet pystyisi nykyisin lainkaan työhön',
  },
  koulutusTutkintoOn: {
    defaultMessage: 'Olen suorittanut tutkinnon tai saanut ammattipätevyyden',
  },
  koulutusTutkinto: {
    defaultMessage: 'Suoritetun tutkinnon tai pätevyyden taso',
  },
  koulutusOppiminen: {
    defaultMessage:
      'Koulutuksen tarjoama joustava ja yksilöllisen opintopolku on tehostanut omaa oppimistani',
  },
  koulutusOppimisenTuki: {
    defaultMessage:
      'Koulutuksen aikana tarjottu tuki ja ohjaus ovat tehostaneet omaa oppimistani',
  },
  koulutusMenetelmat: {
    defaultMessage:
      'Mielestäni koulutuksessa käytetyt opetusmenetelmät ovat lisänneet omaa kykyäni vastata työelämän muuttuneisiin tarpeisiin (esimerkiksi digitalisoituminen)',
  },
  koulutusMahdollisuudet: {
    defaultMessage:
      'Arvioni mukaan mahdollisuuteni työllistymiseen tai työssä jatkamiseen ovat koulutuksen / kehittämistoimenpiteen seurauksena parantuneet',
  },
  koulutusJarjestelyt: {
    defaultMessage:
      'Mielestäni koulutusjärjestelyt ovat sopineet hyvin omaan elämäntilanteeseeni (esimerkiksi koulutusjärjestelyjen joustavuus) ja tukeneet oppimistani',
  },
  koulutusMateriaalit: {
    defaultMessage:
      'Mielestäni koulutuksen fyysinen ympäristö sekä oppimateriaalit olivat laadukkaat ja tehostivat omaa oppimistani',
  },
  koulutusKouluttajaYhteydet: {
    defaultMessage:
      'Mielestäni kouluttajan hyvät ja toimivat työelämäyhteydet ovat tehostaneet omaa oppimistani',
  },
  koulutusKouluttajaAsiantuntemus: {
    defaultMessage:
      'Mielestäni kouluttajan asiantuntemus vastasi koulutuksen tavoitteisiin ja tehosti omaa oppimistani',
  },
  koulutusOsaaminen: {
    defaultMessage:
      'Arvioni mukaan oma ammatillinen osaamiseni on koulutuksen / kehittämistoimenpiteen seurauksena parantunut',
  },
  koulutusVastaavuus: {
    defaultMessage:
      'Mielestäni oma osaamiseni vastaa nyt koulutuksen / kehittämistoimenpiteen jälkeen paremmin muuttuviin työelämän tarpeisiin',
  },
  koulutusItseluottamus: {
    defaultMessage:
      'Mielestäni oma ammatillinen itseluottamukseni (tai työnhaun motivaatio) on parantunut koulutuksen seurauksena',
  },
  koulutusKouluttaminen: {
    defaultMessage:
      'Mielestäni kehittämistoimen ansiosta oman työorganisaationi kyky tarjota laadukasta koulutusta sekä osaamisen kehittämistä edistävää ohjausta ja tukea on parantunut (esim. hanke, jossa kohteena oppilaitoksen antaman koulutuksen kehittäminen, ei suora koulutushanke)',
  },
  koulutusIlmapiiri: {
    defaultMessage:
      'Mielestäni koulutuksen oppimisilmapiiri oli myönteistä ja kannustavaa ja tehosti omaa oppimistani',
  },
  arvioJohtaminen: {
    defaultMessage:
      'Hankkeen seurauksena johtaminen (ml. esimiestyö) on työpaikallanne parantunut',
  },
  arvioOrganisointi: {
    defaultMessage:
      'Työn organisointi on työpaikallanne parantunut ja työnteon tavat tehostuneet',
  },
  arvioOsaaminen: {
    defaultMessage:
      'Henkilöstön osaaminen ja ammattitaito ovat työpaikallanne parantuneet',
  },
  arvioTurvallisuus: {
    defaultMessage:
      'Työturvallisuus sekä siihen liittyvät käytänteet ovat parantuneet työpaikallanne',
  },
  arvioIlmapiiri: {
    defaultMessage: 'Työilmapiiri on työyhteisössänne parantunut',
  },
  arvioVuorovaikutus: {
    defaultMessage:
      'Yhteistyö ja vuorovaikutus ovat parantuneet työyhteisössänne',
  },
  arvioJaksaminen: {
    defaultMessage:
      'Työssäjaksaminen ja työssä pysyminen on parantunut työpaikallanne',
  },
  arvioOsallistuminen: {
    defaultMessage:
      'Henkilöstön osallistumis- ja vaikutusmahdollisuudet ovat parantuneet',
  },
  arvioTyonantajanHR: {
    defaultMessage: 'Olen organisaation vastuuhenkilö/hr-vastaava',
  },
  arvio_tyonantajan_hr_1: { defaultMessage: 'Kyllä' },
  arvio_tyonantajan_hr_2: { defaultMessage: 'Ei' },
  arvioTyonantajanNimi: { defaultMessage: 'Työnantajan nimi' },
  arvioTyonantajanTunnus: { defaultMessage: 'Y-tunnus' },
  vahvista: { defaultMessage: 'Vahvista' },
  arvioViestinta: {
    defaultMessage: 'Viestintä ja viestintäkäytännöt ovat parantuneet',
  },
  arvioJoustavuus: {
    defaultMessage:
      'Joustavat työntetkotavat ovat lisääntyneet työn ja yksityiselämän yhteensovittamiseksi',
  },
  deleteLopetusilmoitus: {
    defaultMessage: 'Haluatko varmasti poistaa lopetusilmoituksen?',
  },
  deleteAloitusilmoitus: {
    defaultMessage: 'Haluatko varmasti poistaa aloitusilmoituksen?',
  },
  deleteUser: { defaultMessage: 'Haluatko varmasti poistaa käyttäjän?' },
  dbError: { defaultMessage: 'Järjestelmävirhe. Ole hyvä ja yritä uudelleen.' },
  hetuError: {
    defaultMessage: 'Virheellinen henkilötunnus. Ole hyvä ja tarkista tiedot.',
  },
  sameFound: {
    defaultMessage:
      'Järjestelmään on jo tallennettu samankaltainen henkilö. Valitse "Tallenna näillä tiedoilla", jos haluat ohittaa henkilön samankaltaisuus tarkistuksen.',
  },
  samesFound: {
    defaultMessage:
      'Järjestelmään on jo tallennettu samankaltaisia henkilöitä. Valitse "Tallenna näillä tiedoilla", jos haluat ohittaa henkilön samankaltaisuus tarkistuksen.',
  },
  sameFoundList: { defaultMessage: 'Löydetty osallistuja:' },
  samesFoundList: { defaultMessage: 'Löydetyt osallistujat:' },
});
