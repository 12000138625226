import { NativeSelect } from '@mui/material';
import { SelectField } from '@esrh/core/src/FormUtil/Fields';
import { useFormState } from 'react-use-form-state';
import { useIntl } from 'react-intl';
import messages from 'messages';
import LoadButton from './LoadButton';
import { REPORTS } from '../../../../util';
import { styled } from '@mui/material/styles';
import { rootStyle, selectStyle } from '@esrh/core/src/FormUtil/Styles';

const toimintalinjaList = [
  { codeOrder: '1', codeId: 'TL_3', codeValue: 'TL_3' },
  { codeOrder: '2', codeId: 'TL_4', codeValue: 'TL_4' },
  { codeOrder: '3', codeId: 'TL_5', codeValue: 'TL_5' },
  { codeOrder: '4', codeId: 'TL_9', codeValue: 'TL_9' },
];
const erityistavoiteList = [
  { codeOrder: '1', codeId: '6.1', codeValue: '6.1' },
  { codeOrder: '2', codeId: '7.1', codeValue: '7.1' },
  { codeOrder: '3', codeId: '8.1', codeValue: '8.1' },
  { codeOrder: '4', codeId: '9.1', codeValue: '9.1' },
  { codeOrder: '5', codeId: '9.2', codeValue: '9.2' },
  { codeOrder: '6', codeId: '10.1', codeValue: '10.1' },
  { codeOrder: '7', codeId: '12.3', codeValue: '12.3' },
  { codeOrder: '8', codeId: '12.4', codeValue: '12.4' },
];

const StyledForm = styled('form')(({ theme }) => ({
  ...rootStyle(theme),
  ...selectStyle(theme),
}));

export default function HankeReport({ defaults }) {
  const intl = useIntl();
  const [formState, { text }] = useFormState();

  return (
    <StyledForm
      className={'form'}
      onSubmit={async (event) => {
        event.preventDefault();
      }}
    >
      <SelectField label={intl.formatMessage(messages.toimintalinja)}>
        <NativeSelect {...text('toimintaLinja')}>
          <option value="" disabled>
            {intl.formatMessage(messages.notSelected)}
          </option>
          {toimintalinjaList.map(({ codeId }) => (
            <option key={codeId} value={codeId}>
              {codeId}
            </option>
          ))}
        </NativeSelect>
      </SelectField>
      <SelectField label={intl.formatMessage(messages.erityistavoite)}>
        <NativeSelect {...text('erityistavoite')}>
          <option value="" disabled>
            {intl.formatMessage(messages.notSelected)}
          </option>
          {erityistavoiteList.map(({ codeId }) => (
            <option key={codeId} value={codeId}>
              {codeId}
            </option>
          ))}
        </NativeSelect>
      </SelectField>
      <LoadButton
        {...defaults}
        values={formState.values}
        report={REPORTS.Hvo}
      />
    </StyledForm>
  );
}
