export const customConfig = ({ palette, ...theme }) => ({
  ...theme,
  palette: {
    ...palette,
    info: {
      ...palette.info,
      main: '#007DB3',
    },
    primary: {
      ...palette.primary,
      main: '#2874D7',
      light: '#cfdff0',
      dark: '#0c4da2',
    },
    secondary: {
      dark: '#0c4da2',
      light: '#cfdff0',
      main: '#0c4da2',
    },
    text: {
      ...palette.text,
      disabled: palette.grey[600],
    },
  },
});
