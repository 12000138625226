import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Page from '../../../layouts/Page';
import { path as frontPage } from '../Frontpage';
import ErrorFallback from '@esrh/core/src/Alert/ErrorFallback';

export default ({ error }) => (
  <Page
    id={'accordion-wrapper-error'}
    topLeftContent={
      <Link to={frontPage}>
        <FormattedMessage defaultMessage={'Palaa etusivulle'} />
      </Link>
    }
  >
    <br />
    <ErrorFallback error={error} />
  </Page>
);
