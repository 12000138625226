import { Route, Switch } from 'react-router-dom';
import { IsAuthenticated } from '@visma/react-keycloak';
import NotFound from '@esrh/core/src/Alert/NotFound';
import * as home from './Home';
import * as login from './Login';
import * as hanketoteuttaja from './Hanketoteuttaja';
import * as hvo from './Hvo';
import ErrorFallback from '@esrh/core/src/Alert/ErrorFallback';
import Page from '../layouts/Page';
import { FormattedMessage } from 'react-intl';

export default function Routes() {
  return (
    <Switch>
      <Route {...home} />
      <Route {...login} />
      <Route {...hanketoteuttaja} />
      <IsAuthenticated
        fallback={
          <Page>
            <ErrorFallback
              error={
                <FormattedMessage defaultMessage="Sinun tulee tunnistautua tarkastellaksesi sivua" />
              }
            />
          </Page>
        }
      >
        <Route {...hvo} />
      </IsAuthenticated>
      <Route component={NotFound} />
    </Switch>
  );
}
