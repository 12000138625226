import React from 'react';
import { IconButton } from '@mui/material';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material';

export default function Pagination({ page, onPageChange, count, rowsPerPage }) {
  const lastPage = Math.floor(count / rowsPerPage);
  return (
    <div>
      <IconButton
        onClick={(e) => onPageChange(e, 0)}
        aria-label="first page"
        disabled={page === 0}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page - 1)}
        aria-label="previous page"
        disabled={page - 1 < 0}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page + 1)}
        aria-label="next page"
        disabled={page + 1 > lastPage}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, lastPage)}
        aria-label="last page"
        disabled={page === lastPage}
      >
        <LastPage />
      </IconButton>
    </div>
  );
}
