import { Button, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import displayNames from '@visma/react-app-locale-utils/lib/displayNames';
import useDisplayName from '@visma/react-app-locale-utils/lib/useDisplayName';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale';
import { FormattedMessage } from 'react-intl';
import WithTimer from './Alert/WithTimer';
import { useState } from 'react';
import messages from '../../messages';
import fi from 'images/fi-FI.png';
import sv from 'images/sv-SE.png';

const SwitcherList = styled(List)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)}px 0px ${theme.spacing(4)}px`,
  '&> li': {
    padding: 0,
    '& > button': {
      padding: 0,
      minWidth: 0,
      marginLeft: 10,
      borderRadius: 0,
      minHeight: 0,
      border: `1px solid ${theme.palette.grey[500]}`,
    },
  },
}));

export default function LocaleSwitcher() {
  const [alert, setAlert] = useState(false);
  const [locale, setLocale] = useLocale();

  return (
    <>
      <WithTimer
        severity={'success'}
        isOpen={alert}
        setOpen={setAlert}
        style={{ marginBottom: 0 }}
      >
        <FormattedMessage
          {...messages.localeSet}
          values={{ value: useDisplayName() }}
        />
      </WithTimer>
      <SwitcherList component="nav" aria-label="kieli">
        {displayNames.map((l) => (
          <ListItem
            key={l.locale}
            style={{ padding: 0 }}
            className={locale === l.locale ? 'active' : ''}
          >
            <Button
              onClick={() => {
                setLocale(l.locale);
                setAlert(true);
              }}
            >
              <img
                src={l.locale === 'sv-SE' ? sv : fi}
                alt={l.value}
                height="15"
              />
            </Button>
          </ListItem>
        ))}
      </SwitcherList>
    </>
  );
}
