import { Children } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

const MainContent = styled('main')(({ theme }) => ({
  padding: theme.spacing(10),
  paddingTop: 0,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(6),
    paddingTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1),
    paddingTop: 0,
  },
}));

export default function PageContainer({ children }) {
  let appBar;
  let navigation;
  let content;

  Children.forEach(children, (child) => {
    const element = {
      [PageContainer.AppBar.type]() {
        appBar = child;
      },
      [PageContainer.Navigation.type]() {
        navigation = child;
      },
      [PageContainer.Content.type]() {
        content = child;
      },
    }[child.type.type];

    if (!element) {
      throw new Error('Unknown child type for PageWithStaticHeader');
    }
    element();
  });

  return (
    <>
      <AppBar enableColorOnDark position="static">
        <Toolbar disableGutters>{appBar}</Toolbar>
      </AppBar>
      <MainContent>
        {navigation}
        {content}
      </MainContent>
    </>
  );
}
