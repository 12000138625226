import { LinearProgress } from '@mui/material';
import { Layout } from '@esrh/core/src/LayoutFallback';
import ErrorFallback from '@esrh/core/src/Alert/ErrorFallback';
import Theme from '@esrh/theme';
import AppProvider from '@visma/react-app-super-template';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Page from './layouts/Page';
import Routes from './Routes';
import useSetDocumentTitle from './layouts/useSetDocumentTitle';
import config from '@visma/public.config';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';

export default function App() {
  useSetDocumentTitle('ESR-Henkilö');
  const instance = createInstance(config.matomo);
  return (
    <Theme>
      <MatomoProvider value={instance}>
        <AppProvider fallback={<LinearProgress />}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Layout FallbackComponent={Page}>
              <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Routes />
                </QueryParamProvider>
              </BrowserRouter>
            </Layout>
          </ErrorBoundary>
        </AppProvider>
      </MatomoProvider>
    </Theme>
  );
}
