import Typography from '@mui/material/Typography';
import { IsAuthenticated } from '@visma/react-keycloak';
import { FormattedMessage } from 'react-intl';
import Authenticate from './Authenticate';
import eu_logo from 'images/eu_logo.png';

export default function AppBar() {
  return (
    <>
      <div className={'imageContainer'}>
        <img src={eu_logo} alt="" height="100" />
        <div>
          <Typography>
            <FormattedMessage defaultMessage={'Euroopan unioni'} />
          </Typography>
          <Typography>
            <FormattedMessage defaultMessage={'Euroopan sosiaalirahasto'} />
          </Typography>
        </div>
      </div>
      <div className={'headerContainer'}>
        <Typography variant="h6" component="h1">
          <FormattedMessage defaultMessage={'ESR HENKILÖ'} />
        </Typography>
        <Typography>
          <FormattedMessage
            defaultMessage={'Ohjelmakausi {years}'}
            values={{ years: '2014-2020' }}
          />
        </Typography>
      </div>
      <IsAuthenticated fallback={<Authenticate />}>
        <Authenticate isAuthenticated />
      </IsAuthenticated>
    </>
  );
}
