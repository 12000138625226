import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';
import messages from '@esrh/app/src/messages';

export default function NoInfo({
  canEdit,
  onAddClick,
  isAloitusilmoitus,
  hasAloitusilmoitus,
}) {
  return (
    <div>
      {canEdit && (
        <Button
          onClick={onAddClick}
          color="primary"
          disabled={!isAloitusilmoitus && !hasAloitusilmoitus}
          className={'as-link'}
        >
          <Typography>
            {isAloitusilmoitus ? (
              <FormattedMessage defaultMessage={'Lisää aloitusilmoitus'} />
            ) : (
              <FormattedMessage {...messages.addLopetusilmoitus} />
            )}
          </Typography>
        </Button>
      )}
      <Typography>
        {isAloitusilmoitus ? (
          <FormattedMessage
            defaultMessage={'Osallistujalla ei ole aloitusilmoituksia.'}
          />
        ) : (
          <>
            <FormattedMessage
              defaultMessage={'Osallistujalla ei ole lopetusilmoituksia.'}
            />{' '}
            {!hasAloitusilmoitus && canEdit && (
              <FormattedMessage
                defaultMessage={
                  'Aloitusilmoitus on lisättävä ennen lopetusilmoitusta'
                }
              />
            )}
          </>
        )}
      </Typography>
    </div>
  );
}
