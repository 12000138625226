export HankePanel from './HankePanel';
export ActionPanel from './ActionPanel';
export IlmoitusPanel from './IlmoitusPanel';
export IlmoitusFormPanel from './IlmoitusFormPanel';

export const PANEL = {
  info: 'panel_info',
  search: 'panel_search',
  add: 'panel_add',
  edit: 'panel_edit',
  user: 'user',
  error: 'error',
  lopetusilmoitus: 'lopetusilmoitus',
  aloitusilmoitus: 'aloitusilmoitus',
  addAloitusilmoitus: 'addAloitusilmoitus',
  addLopetusilmoitus: 'addLopetusilmoitus',
};
