import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { getReport } from 'api';

export default function LoadButton({
  report,
  format,
  loading,
  setLoading,
  locale,
  contained,
  suffix,
  values,
  ...props
}) {
  return (
    <Button
      variant={contained ? 'contained' : 'text'}
      color="primary"
      className={contained ? '' : 'as-link'}
      onClick={() =>
        fetchReport({
          report,
          suffix,
          loading,
          setLoading,
          locale,
          format,
          ...props,
          ...values,
        })
      }
    >
      {loading.includes(`${report}${suffix}`) ? (
        <>
          <FormattedMessage defaultMessage={'Ladataan'} />
          <CircularProgress
            color="secondary"
            size={'1rem'}
            style={{ marginLeft: 10 }}
          />
        </>
      ) : (
        <FormattedMessage
          {...messages[`${report.replace(/-/g, '')}${suffix || ''}`]}
        />
      )}
    </Button>
  );
}

const fetchReport = async ({
  report,
  suffix,
  loading,
  setLoading,
  locale,
  format,
  ...props
}) => {
  setLoading(loading.concat(`${report}${suffix}`));
  const reportData = await getReport({
    language: locale,
    format,
    reportType: report,
    ...props,
  });

  if (reportData.error)
    alert(
      'Raportin lataamisessa tapahtui virhe. Ole hyvä ja yritä uudelleen. Jos ongelma toistuu, ota yhteyttä ylläpitoon'
    );
  else {
    const link = document.createElement('a');
    link.href = `data:${reportData.contentType};charset=utf-8;base64,${reportData.content}`;
    link.download = reportData.fileName;
    link.click();
  }
  setLoading(loading);
};
