import React from 'react';
import { useIntl } from 'react-intl';
import SuspenseOverlay from 'suspense-overlay';
import messages from 'messages';
import SortableTable from '@esrh/core/src/SortableTable';
import CircularProgress from '@mui/material/CircularProgress';

function createData({ osallistujaId, sukunimi, etunimi, hetu, hankeId }) {
  return { id: osallistujaId, sukunimi, etunimi, hetu, hankeId };
}

export default function SearchResults({ data, onOpenUser }) {
  const intl = useIntl();
  const cell = ({ id, noSort }) => ({
    id,
    sortable: !noSort,
    label: intl.formatMessage(messages[id]),
  });

  const cells = [
    cell({ id: 'sukunimi' }),
    cell({ id: 'etunimi' }),
    cell({ id: 'hetu', noSort: true }),
    cell({ id: 'hankeId' }),
  ];

  return (
    <SuspenseOverlay fallback={<CircularProgress />}>
      <SortableTable
        headCells={cells}
        rowBuilder={createData}
        onOpen={onOpenUser}
        data={data}
      />
    </SuspenseOverlay>
  );
}
