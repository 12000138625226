import { Link, Redirect } from 'react-router-dom';
import Page from 'components/layouts/Page';
import { IsAuthenticated } from '@visma/react-keycloak';
import { FormattedMessage } from 'react-intl';
import { Paper, Typography } from '@mui/material';
import { path as hvo } from '../Hvo/Frontpage';
import { useKeycloak } from '@react-keycloak/web';
import config from '@visma/public.config';
import { styled } from '@mui/material/styles';

export const path = '/hvologin';
export const exact = true;

const LinkWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

export const component = function Login() {
  const { keycloak } = useKeycloak();

  return (
    <Page>
      <StyledPaper elevation={3}>
        <Typography>
          <FormattedMessage defaultMessage={'Et ole kirjautunut'} />
        </Typography>
        <LinkWrapper>
          <IsAuthenticated
            fallback={
              <Link
                to={'#'}
                onClick={() => keycloak.login(config.keycloakLogin)}
              >
                <Typography>
                  <FormattedMessage
                    defaultMessage={'Kirjaudu Suomi.fi-palveluun'}
                  />
                </Typography>
              </Link>
            }
          >
            <Redirect to={hvo} />
          </IsAuthenticated>
        </LinkWrapper>
      </StyledPaper>
    </Page>
  );
};
