import { Alert, AlertTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function ErrorFallback({ error }) {
  return (
    <Alert severity="warning">
      <AlertTitle>
        <FormattedMessage defaultMessage="Jotain meni pieleen" />
      </AlertTitle>
      {error?.stack ?? error}
    </Alert>
  );
}
