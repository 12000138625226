import { Suspense } from 'react';
import PageContainer from '@esrh/core/src/PageContainer';
import { Box, CircularProgress } from '@mui/material';
import AppBar from './AppBar';
import LocaleSwitcher from '@esrh/core/src/LocaleSwitcher';

export default function Page({ topLeftContent, children }) {
  return (
    <PageContainer>
      <PageContainer.AppBar>
        <AppBar />
      </PageContainer.AppBar>
      <PageContainer.Navigation>
        <Box display="flex" justifyContent={'space-between'}>
          <div style={{ alignSelf: 'center' }}>{topLeftContent}</div>
          <LocaleSwitcher />
        </Box>
      </PageContainer.Navigation>
      <PageContainer.Content>
        <Suspense fallback={<CircularProgress />}>{children}</Suspense>
      </PageContainer.Content>
    </PageContainer>
  );
}
