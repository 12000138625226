const MuiDialog = ({ palette, spacing }) => ({
  styleOverrides: {
    container: {
      '& .MuiDialogTitle-root': {
        background: palette.secondary.light,
      },
      '& .MuiDialogContent-root': {
        paddingTop: 24,
        '& .MuiTypography-colorTextSecondary': {
          color: 'black',
        },
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'space-between',
        padding: spacing(3),
      },
    },
    paperWidthSm: {
      maxWidth: 700,
      minWidth: 600,
    },
  },
});

export default MuiDialog;
