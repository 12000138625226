import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { format, isValid } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@esrh/app/src/messages';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import common from '@esrh/messages/src/main';
import {
  deleteOsallistuja,
  editOsallistuja,
  postOsallistuja,
} from '@esrh/app/src/api';
import ExistingModal from './ExistingModal';
import {
  parseDate,
  useDateValidator,
  useEmailValidator,
  useHetuValidator,
  useZipValidator,
} from '@esrh/core/src/FormUtil/Validators';
import { BasicField, getAdornment } from '@esrh/core/src/FormUtil/Fields';
import ConfirmDialog from '@esrh/core/src/ConfirmDialog';
import CustomAlert from '@esrh/core/src/Alert/CustomAlert';
import { styled } from '@mui/material/styles';
import { rootStyle } from '../../FormUtil/Styles';

const HetuField = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  lineHeight: '40px',
}));

const StyledForm = styled('form')(({ theme }) => ({
  ...rootStyle(theme),
}));

function getHetu(date, hetuEnd) {
  if (hetuEnd && hetuEnd.length === 4) {
    const result = parseDate(date);
    if (isValid(result))
      return (
        format(result, 'ddMMyy') +
        (result.getFullYear() < 2000 ? '-' : 'A') +
        hetuEnd
      );
  }
  return undefined;
}

const RemoveButton = ({ setConfirmationOpen, disabled }) => (
  <Button
    variant="contained"
    color="primary"
    disabled={disabled}
    type={'button'}
    onClick={() => setConfirmationOpen(true)}
  >
    <FormattedMessage defaultMessage="Poista henkilö" />
  </Button>
);

export default function Form({
  userInfo,
  hankeId,
  hasSubmitted,
  onUserRemoved,
}) {
  const intl = useIntl();
  const isEdit = !!userInfo;
  const [formState, { text, email, radio }] = useFormState(userInfo);
  const [openAlert, setOpenAlert] = useState(undefined);
  const [existing, setExisting] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const removeUser = useCallback(async () => {
    await deleteOsallistuja(userInfo.osallistujaId).then(() => onUserRemoved());
  }, [userInfo.osallistujaId, onUserRemoved]);

  useEffect(() => {
    if (!userInfo) formState.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const lock = () => ({
    disabled: isEdit,
    InputProps: isEdit ? getAdornment() : {},
  });

  const confirmed = () => {
    setConfirmationOpen(false);
    removeUser();
  };

  const submitted = ({
    existingOsallistujaList,
    addedOsallistuja,
    editedOsallistuja,
    error,
  }) => {
    if (existingOsallistujaList) setExisting(existingOsallistujaList);
    else if (addedOsallistuja || editedOsallistuja)
      hasSubmitted(addedOsallistuja || editedOsallistuja);
    else {
      const hetuErrors = [400, 403];
      if (hetuErrors.includes(error))
        formState.setFieldError(
          'hetuEnd',
          intl.formatMessage(messages.hetuError)
        );
      else setOpenAlert('dbError');
    }
  };

  const hetu = getHetu(
    formState.values.syntymaPaiva,
    formState.values.hetuEnd
  )?.toUpperCase();

  const submitUser = async (override) => {
    setExisting(false);
    const osallistuja = {
      ...formState.values,
      hankeId,
      hetu,
      sukupuoli: parseInt(formState.values.sukupuoli),
    };

    if (isEdit) editOsallistuja({ osallistuja }).then(submitted);
    else
      await postOsallistuja({ osallistuja, override: !!override }).then(
        submitted
      );
  };

  const resetValidator = (field) => {
    const value = formState.values[field];
    formState.resetField(field);
    formState.setField(field, value);
  };

  return (
    <>
      <ConfirmDialog
        open={confirmationOpen}
        content={'deleteUser'}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={confirmed}
      />
      {existing && (
        <ExistingModal
          userList={existing}
          onClose={() => setExisting(false)}
          onConfirm={() => submitUser(true)}
        />
      )}
      <StyledForm
        className={'form'}
        onSubmit={async (event) => {
          event.preventDefault();
          if (Object.values(formState.validity).some((val) => !val)) {
            setOpenAlert('formError');
          } else {
            submitUser();
          }
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomAlert
              severity={'error'}
              isOpen={openAlert}
              setOpen={setOpenAlert}
            >
              {openAlert && <FormattedMessage {...messages[openAlert]} />}
            </CustomAlert>
          </Grid>
          <Grid item md={6} sm={12}>
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.etunimi)}
              required={true}
              {...text('etunimi')}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.sukunimi)}
              required={true}
              {...text('sukunimi')}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.sposti)}
              {...email({
                name: 'sposti',
                validate: useEmailValidator(),
                validateOnBlur: !formState.errors.sposti,
              })}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.syntymaPaivaH)}
              required={true}
              {...text({
                name: 'syntymaPaiva',
                validate: useDateValidator(),
                validateOnBlur: !formState.errors.syntymaPaiva,
              })}
              {...lock()}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.hetuEnd)}
              {...text({
                name: 'hetuEnd',
                validate: useHetuValidator(formState),
                onChange: () => resetValidator('sukupuoli'),
              })}
              {...lock()}
            />
            <HetuField>
              <FormattedMessage
                defaultMessage={'Henkilötunnus: {hetu}'}
                values={{ hetu }}
              />
            </HetuField>
          </Grid>
          <Grid item md={6} sm={12}>
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.katuosoite)}
              {...text('katuosoite')}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.postinumero)}
              {...text({
                name: 'postinumero',
                validate: useZipValidator(formState),
                validateOnBlur: !formState.errors.postinumero,
              })}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.postitoimipaikka)}
              {...text('postitoimipaikka')}
            />
            <BasicField
              errors={formState.errors}
              label={intl.formatMessage(messages.puhelinnumero)}
              {...text('puhelinnumero')}
            />
            <FormControl
              component="fieldset"
              error={!!formState.errors.sukupuoli}
            >
              <FormLabel component="legend" className={'MuiTypography-srOnly'}>
                <FormattedMessage {...messages.sukupuoli} />
              </FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  label={intl.formatMessage(messages.male)}
                  {...radio({
                    name: 'sukupuoli',
                    ownValue: '1',
                    validate: useHetuValidator(formState),
                    onChange: () => resetValidator('hetuEnd'),
                  })}
                  value={'1'}
                  control={<Radio color="primary" required disabled={isEdit} />}
                />
                <FormControlLabel
                  label={intl.formatMessage(messages.female)}
                  {...radio({
                    name: 'sukupuoli',
                    ownValue: '2',
                    validate: useHetuValidator(formState),
                    onChange: () => resetValidator('hetuEnd'),
                  })}
                  value={'2'}
                  control={<Radio color="primary" required disabled={isEdit} />}
                />
              </RadioGroup>
              <FormHelperText>{formState.errors.sukupuoli}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button variant="contained" color="primary" type={'submit'}>
              <FormattedMessage {...common.submit} />
            </Button>
            {userInfo &&
            (userInfo.aloitusilmoitus || userInfo.lopetusilmoitus) ? (
              <Tooltip
                placement="top-end"
                title={intl.formatMessage({
                  defaultMessage:
                    'Osallistujalla on aktiivisia aloitus- tai lopetusilmoituksia. Poistaaksesi osallistujan, siirry ensin valitun osallistujan tietoihin ja poista kaikki aktiiviset aloitus- ja lopetusilmoitukset.',
                })}
              >
                <span>
                  <RemoveButton
                    setConfirmationOpen={setConfirmationOpen}
                    disabled={true}
                  />
                </span>
              </Tooltip>
            ) : (
              userInfo && (
                <RemoveButton setConfirmationOpen={setConfirmationOpen} />
              )
            )}
          </Grid>
        </Grid>
      </StyledForm>
    </>
  );
}
