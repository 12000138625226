import React from 'react';
import { Button, TextField } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';
import common from '@esrh/messages/src/main';
import { useFormState } from 'react-use-form-state';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { path as hanke } from './Hanke';
import { styled } from '@mui/material/styles';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(3),
}));

export default function HankeSelection() {
  const intl = useIntl();
  const history = useHistory();
  const [formState, { text }] = useFormState();

  const { trackEvent } = useMatomo();

  const trackEventToMatomo = () => {
    trackEvent({
      category: 'search',
      action: 'search-hanke',
    });
  };

  return (
    <form
      style={{ display: 'flex' }}
      onSubmit={async (event) => {
        event.preventDefault();
        const hankeId = formState?.values?.hankeId;
        if (hankeId?.length > 3) history.push(generatePath(hanke, { hankeId }));
      }}
    >
      <StyledTextField
        id={'hankeId_id'}
        {...text('hankeId')}
        variant="outlined"
        size="small"
        label={intl.formatMessage(messages['enterHankeId'])}
      />
      <Button
        variant="contained"
        color="primary"
        type={'submit'}
        onClick={trackEventToMatomo}
      >
        <FormattedMessage {...common.search} />
      </Button>
    </form>
  );
}
