import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';
import LayoutFallback from '../LayoutFallback';
import BackButton from '../BackButton';

export default function Forbidden() {
  return (
    <LayoutFallback>
      <Alert severity="error">
        <FormattedMessage defaultMessage="Ei sallittu" />
      </Alert>
      <BackButton />
    </LayoutFallback>
  );
}
