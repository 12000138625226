export const selectStyle = (theme) => ({
  '& .MuiFormControlLabel-root, & .MuiFormLabel-root': {
    margin: 0,
    flex: 1,
    color: theme.palette.text.primary,
  },
  '& .MuiFormControlLabel-label': {
    flex: 1,
    paddingRight: theme.spacing(3),
  },
  '& .MuiInputBase-formControl': {
    flex: 1,
    marginTop: 0,
  },
});

export const rootStyle = (theme) => ({
  '& .MuiFormControl-root': {
    marginBottom: theme.spacing(1),
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
  },
});
