import React from 'react';
import { useIntl } from 'react-intl';
import messages from '@esrh/app/src/messages';
import CustomAccordion from '@esrh/core/src/CustomAccordion';
import Aloitusilmoitus from '../Info/Aloitusilmoitus';
import NoInfo from '../Info/NoInfo';
import Lopetusilmoitus from '../Info/Lopetusilmoitus';
import { PANEL } from './index';

export default function IlmoitusPanel({
  aloitusilmoitus,
  lopetusilmoitus,
  expanded,
  setAction,
  canEdit,
  ...props
}) {
  const intl = useIntl();

  return (
    <>
      <CustomAccordion
        id={props.idStart}
        isExpanded={expanded.includes(props.idStart)}
        handleChange={props.handleChange}
        heading={intl.formatMessage(messages.userInfoStart)}
      >
        {aloitusilmoitus ? (
          <Aloitusilmoitus
            aloitusilmoitus={aloitusilmoitus}
            erityistavoite={props.erityistavoite}
            data={props.esrhData}
            hasLopetusilmoitus={!!lopetusilmoitus}
            addAloitusilmoitus={setAction(PANEL.addAloitusilmoitus)}
            onDeleted={props.onClose}
            canEdit={canEdit}
          />
        ) : (
          <NoInfo
            isAloitusilmoitus={true}
            canEdit={canEdit}
            onAddClick={setAction(PANEL.addAloitusilmoitus)}
          />
        )}
      </CustomAccordion>
      <CustomAccordion
        id={props.idEnd}
        isExpanded={expanded.includes(props.idEnd)}
        handleChange={props.handleChange}
        heading={intl.formatMessage(messages.userInfoEnd)}
      >
        {lopetusilmoitus ? (
          <Lopetusilmoitus
            lopetusilmoitus={lopetusilmoitus}
            erityistavoite={props.erityistavoite}
            data={props.esrhData}
            addLopetusilmoitus={setAction(PANEL.addLopetusilmoitus)}
            onDeleted={props.onClose}
            canEdit={canEdit}
          />
        ) : (
          <NoInfo
            canEdit={canEdit}
            onAddClick={setAction(PANEL.addLopetusilmoitus)}
            hasAloitusilmoitus={!!aloitusilmoitus}
          />
        )}
      </CustomAccordion>
    </>
  );
}
