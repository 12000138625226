import { defineMessages } from 'react-intl';

export default defineMessages({
  back: { defaultMessage: 'Takaisin' },
  cancel: { defaultMessage: 'Peruuta' },
  close: { defaultMessage: 'Sulje' },
  delete: { defaultMessage: 'Poista' },
  submit: { defaultMessage: 'Tallenna' },
  edit: { defaultMessage: 'Muokkaa' },
  search: { defaultMessage: 'Hae' },
  yes: { defaultMessage: 'Kyllä' },
  no: { defaultMessage: 'Ei' },
  localeSet: { defaultMessage: 'Käytät nyt palvelua kielellä {value}' },
});
