import React from 'react';
import { useIntl } from 'react-intl';
import messages from '@esrh/app/src/messages';
import HankeInfo from '../Info/HankeInfo';
import CustomAccordion from '@esrh/core/src/CustomAccordion';

export default function HankePanel({ id, hanke, expanded, handleChange }) {
  const intl = useIntl();

  return (
    <CustomAccordion
      id={id}
      isExpanded={expanded.includes(id)}
      handleChange={handleChange}
      heading={intl.formatMessage(messages.hankeWithName, {
        name: hanke.hankeNimi,
      })}
    >
      <HankeInfo hanke={hanke} />
    </CustomAccordion>
  );
}
