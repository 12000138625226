import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@esrh/app/src/messages';
import { Button, NativeSelect, TableCell, TableContainer } from '@mui/material';
import { format } from 'date-fns';
import { SelectField, Selector } from '@esrh/core/src/FormUtil/Fields';
import { styled } from '@mui/material/styles';

const tyokykyKeys = [...Array(11).keys()].map((x) => x);

export const tyokykyForm = ['10.1'];
export const workingId = '6';
export const trueId = '1';
export const koulutusForm = ['9.1', '9.2'];
export const arvioForm = ['7.1'];

export const koulutusKeys = [
  'koulutusOppiminen',
  'koulutusOppimisenTuki',
  'koulutusMenetelmat',
  'koulutusMahdollisuudet',
  'koulutusJarjestelyt',
  'koulutusMateriaalit',
  'koulutusKouluttajaYhteydet',
  'koulutusKouluttajaAsiantuntemus',
  'koulutusOsaaminen',
  'koulutusVastaavuus',
  'koulutusItseluottamus',
  'koulutusKouluttaminen',
  'koulutusIlmapiiri',
];

export const arvioKeys = [
  'arvioJohtaminen',
  'arvioOrganisointi',
  'arvioOsaaminen',
  'arvioTurvallisuus',
  'arvioIlmapiiri',
  'arvioVuorovaikutus',
  'arvioJaksaminen',
  'arvioOsallistuminen',
  'arvioViestinta',
  'arvioJoustavuus',
];

const RemoveButton = ({ setConfirmRemove, className, disabled }) => (
  <Button
    variant="contained"
    color="primary"
    className={className}
    disabled={disabled}
    type={'button'}
    onClick={() => setConfirmRemove(true)}
  >
    <FormattedMessage defaultMessage={'Poista aloitusilmoitus'} />
  </Button>
);

export const FormField = ({
  component,
  label,
  errors,
  options,
  required = false,
  ...text
}) => (
  <SelectField required={required} component={component} label={label}>
    <Selector props={{ errors, label, ...text }} options={options} />
  </SelectField>
);

export const TyokykyField = ({ className, label, placeholder, ...text }) => (
  <SelectField required className={className} label={label}>
    <NativeSelect {...text}>
      <option value="" disabled>
        {placeholder}
      </option>
      {tyokykyKeys.map((value) => (
        <option key={value + 'v'} value={value}>
          {value}
        </option>
      ))}
    </NativeSelect>
  </SelectField>
);

export const StyledTable = styled(TableContainer)(({ theme }) => ({
  '& td': {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  '& th': {
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
}));

export const StyledCell = styled(TableCell)(({ theme }) => ({
  padding: 5,
  whiteSpace: 'normal',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const StyledRemoveButton = styled(RemoveButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const getOptions = (values) => {
  return values
    .sort((a, b) => a.codeOrder - b.codeOrder)
    .map((obj) => ({ label: obj.codeId, value: obj.codeValue }));
};

export const useGetStartData = ({ aloitusilmoitus, showOpo, data }) => {
  const intl = useIntl();
  const getField = (list, value, label) => {
    if (value !== undefined && value !== null) value = value.toString();
    const val = list && list.find((e) => e.codeValue.toString() === value);
    return (
      list && {
        label: intl.formatMessage(messages[label]),
        value: intl.formatMessage(messages[val ? val.codeId : 'unknown']),
      }
    );
  };

  return [
    {
      label: intl.formatMessage({
        defaultMessage: 'Päivämäärä, jolloin osallistuja aloitti hankkeessa',
      }),
      value:
        aloitusilmoitus.aloitusPvm &&
        format(new Date(aloitusilmoitus.aloitusPvm), 'dd.MM.yyyy'),
    },
    getField(
      data.tyollisyystilanneAloitusList,
      aloitusilmoitus.tyollisyysTilanne,
      'tyollisyysTilanne'
    ),
    aloitusilmoitus.tyollisyysTilanne.toString() === workingId &&
      getField(data.tyonantajaList, aloitusilmoitus.tyonantaja, 'tyonantaja'),
    aloitusilmoitus.tyollisyysTilanne.toString() === workingId &&
      getField(
        data.ammattiluokkaList,
        aloitusilmoitus.ammattiluokka,
        'ammattiluokka'
      ),
    showOpo &&
      getField(
        data.koulutusasteAloitusList,
        aloitusilmoitus.koulutusTaso,
        'koulutusTaso'
      ),
    koulutusForm.includes(aloitusilmoitus.tyollisyysTilanne) &&
      getField(
        data.opintoohjaajaAloitusList,
        aloitusilmoitus.ohjauspalvelunAsiantuntija,
        'ohjauspalvelunAsiantuntija'
      ),
    getField(
      data.muutTaustatekijatAloitusList,
      aloitusilmoitus.muutUlkomaalainen,
      'muutUlkomaalainen'
    ),
    getField(
      data.muutTaustatekijatAloitusList,
      aloitusilmoitus.muutVammainen,
      'muutVammainen'
    ),
    getField(
      data.muutTaustatekijatAloitusList,
      aloitusilmoitus.muutHeikkous,
      'muutHeikkous'
    ),
  ].filter(Boolean);
};

export const useGetEndData = (lopetusilmoitus, data) => {
  const intl = useIntl();
  const getField = (list, value, label) => {
    if (value !== undefined && value !== null) value = value.toString();
    return (
      list && {
        label: intl.formatMessage(messages[label]),
        value:
          value &&
          intl.formatMessage(
            messages[list.find((e) => e.codeValue.toString() === value).codeId]
          ),
      }
    );
  };

  return [
    {
      label: intl.formatMessage({ defaultMessage: 'Lopetuspäivämäärä' }),
      value:
        lopetusilmoitus.lopetusPvm &&
        format(new Date(lopetusilmoitus.lopetusPvm), 'dd.MM.yyyy'),
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Paluupäivämäärä' }),
      value:
        lopetusilmoitus.paluuHankkeeseenPvm &&
        format(new Date(lopetusilmoitus.paluuHankkeeseenPvm), 'dd.MM.yyyy'),
    },
    getField(
      data.tyollisyystilanneLopetusList,
      lopetusilmoitus.tyollisyysTilanne,
      'tyollisyysTilanne'
    ),
    getField(
      data.koulutusOnLopetusList,
      lopetusilmoitus.koulutusTutkintoOn,
      'koulutusTutkintoOn'
    ),
    lopetusilmoitus.koulutusTutkintoOn !== '0' &&
      getField(
        data.koulutusLopetus,
        lopetusilmoitus.koulutusTutkinto,
        'koulutusTutkinto'
      ),
  ].filter(Boolean);
};
