import React from 'react';
import { useIntl } from 'react-intl';
import { PANEL } from './index';
import AloitusilmoitusForm from '../Info/AloitusilmoitusForm';
import LopetusilmoitusForm from '../Info/LopetusilmoitusForm';
import CustomAccordion from '@esrh/core/src/CustomAccordion';

export default function IlmoitusFormPanel({
  id,
  osallistujaId,
  esrhData,
  onClose,
  erityistavoite,
  ...props
}) {
  const intl = useIntl();

  return (
    <CustomAccordion
      id={id}
      isExpanded={props.expanded.includes(id)}
      handleChange={props.handleChange}
      heading={
        id === PANEL.addAloitusilmoitus
          ? intl.formatMessage({
              defaultMessage: 'Osallistujan tiedot aloitustilanteessa',
            })
          : intl.formatMessage({
              defaultMessage: 'Osallistujan tiedot lopetustilanteessa',
            })
      }
    >
      {id === PANEL.addAloitusilmoitus ? (
        <AloitusilmoitusForm
          osallistujaId={osallistujaId}
          erityistavoite={erityistavoite}
          hankeAloitusDate={props.hankeAloitusDate}
          data={esrhData}
          onClose={onClose}
        />
      ) : (
        <LopetusilmoitusForm
          osallistujaId={osallistujaId}
          erityistavoite={erityistavoite}
          data={esrhData}
          aloitusDate={props.aloitusDate}
          onClose={onClose}
        />
      )}
    </CustomAccordion>
  );
}
