import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Suspense from 'suspense-overlay';
import messages from '@esrh/app/src/messages';
import SortableTable from '@esrh/core/src/SortableTable';
import WithTimer from '@esrh/core/src/Alert/WithTimer';
import CircularProgress from '@mui/material/CircularProgress';

function createData({
  osallistujaId,
  etunimi,
  sukunimi,
  hetu,
  postitoimipaikka,
  hasAloitusilmoitus,
  hasLopetusilmoitus,
}) {
  return {
    id: osallistujaId,
    sukunimi,
    etunimi,
    hetu,
    postitoimipaikka,
    hasAloitusilmoitus,
    hasLopetusilmoitus,
  };
}

export default function SearchResults({ data, onOpen }) {
  const intl = useIntl();
  const [alert, setAlert] = useState(false);

  const cells = [
    {
      id: 'sukunimi',
      sortable: true,
      label: intl.formatMessage(messages.sukunimi),
    },
    {
      id: 'etunimi',
      sortable: true,
      label: intl.formatMessage(messages.etunimi),
    },
    { id: 'hetu', label: intl.formatMessage(messages.hetu) },
    {
      id: 'postitoimipaikka',
      sortable: true,
      label: intl.formatMessage(messages.postitoimipaikka),
    },
    {
      id: 'hasAloitusilmoitus',
      sortable: true,
      label: intl.formatMessage(messages.aloitusilmoitus),
    },
    {
      id: 'hasLopetusilmoitus',
      sortable: true,
      label: intl.formatMessage(messages.lopetusilmoitus),
    },
  ];

  return (
    <>
      {alert ? (
        <WithTimer
          severity={'warning'}
          isOpen={alert}
          setOpen={setAlert}
          style={{ marginTop: 30 }}
        >
          <FormattedMessage {...messages.tryAgain} />
        </WithTimer>
      ) : (
        <Suspense fallback={<CircularProgress />}>
          <SortableTable
            headCells={cells}
            rowBuilder={createData}
            onOpen={onOpen}
            data={data}
          />
        </Suspense>
      )}
    </>
  );
}
