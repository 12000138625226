import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import messages from '@esrh/app/src/messages';

export default function ExistingModal({ userList, onClose, onConfirm }) {
  const multiple = userList.length > 1;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Tarkista tiedot</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            {...messages[multiple ? 'samesFound' : 'sameFound']}
          />
        </DialogContentText>
        <DialogContentText style={{ fontWeight: 'bold' }}>
          <FormattedMessage
            {...messages[multiple ? 'samesFoundList' : 'sameFoundList']}
          />
        </DialogContentText>
        {userList.map((u, index) => (
          <div key={index}>
            <DialogContentText style={{ margin: 0 }}>
              {u.etunimi} {u.sukunimi}
              {', '}
              {u.hetu}
            </DialogContentText>
            <DialogContentText>
              <span style={{ fontWeight: 'bold' }}>Osoite: </span>
              {u.katuosoite} {u.postinumero} {u.postitoimipaikka}
            </DialogContentText>
            <hr />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          Tallenna näillä tiedoilla
        </Button>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Peruuta
        </Button>
      </DialogActions>
    </Dialog>
  );
}
