import { useCurrentUser, useKeycloak } from '@visma/react-keycloak';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale';
import { Button, Typography } from '@mui/material';
import { AccountBox, PowerSettingsNew } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import config from '@visma/public.config';
import { HasRole } from 'api';
import { ROLES } from '../../../util';

const instructions = {
  manual: {
    'fi-FI': 'ESRH_Käyttöohje.pdf',
    'sv-SE': 'ESR_Bruksanvisning.pdf',
  },
  report: {
    'fi-FI': 'ESRH_Raporttiohje.pdf',
    'sv-SE': 'ESR_Anvisning_för_rapport.pdf',
  },
  login: {
    'fi-FI': 'ESRH_Kirjautumisen_ohje.pdf',
    'sv-SE': 'ESRH_Kirjautumisen_ohje.pdf',
  },
};

const LogoutButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  fontSize: 'initial',
}));

const LogoutBtn = ({ role, keycloak }) => (
  <LogoutButton
    onClick={() => keycloak.logout(config.keycloakLogout[role])}
    color="inherit"
    className={'asLink'}
  >
    <PowerSettingsNew />
    <FormattedMessage defaultMessage={'Kirjaudu ulos'} />
  </LogoutButton>
);

export default function Authenticate({ isAuthenticated }) {
  const instructionRoot = process.env.PUBLIC_URL + '/instructions';
  const user = useCurrentUser();
  const { keycloak } = useKeycloak();
  const [locale] = useLocale();

  return isAuthenticated ? (
    <>
      <div className={'loginContainer'}>
        <AccountBox />
        <Typography>{user?.name}</Typography>
        <HasRole
          role={ROLES.Hvo}
          fallback={
            <LogoutBtn role={ROLES.Hanketoteuttaja} keycloak={keycloak} />
          }
        >
          <LogoutBtn role={ROLES.Hvo} keycloak={keycloak} />
        </HasRole>
      </div>
      <div className={'instructionContainer'}>
        <Link
          to={`${instructionRoot}/${instructions.manual[locale]}`}
          target={'_new'}
        >
          <FormattedMessage defaultMessage={'Käyttöohje'} />
        </Link>
        <Link
          to={`${instructionRoot}/${instructions.report[locale]}`}
          target={'_new'}
        >
          <FormattedMessage defaultMessage={'Raporttiohje'} />
        </Link>
      </div>
    </>
  ) : (
    <div className={'instructionContainer'}>
      <Link
        to={`${instructionRoot}/${instructions.login[locale]}`}
        target={'_new'}
      >
        <FormattedMessage defaultMessage={'Kirjautumisen ohje'} />
      </Link>
    </div>
  );
}
