const MuiToolbar = {
  styleOverrides: {
    regular: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
};

export default MuiToolbar;
