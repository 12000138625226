import Forbidden from '@esrh/core/src/Alert/Forbidden';
import NotFound from '@esrh/core/src/Alert/NotFound';
import { ROLES } from '../../../util';
import { HasRole } from 'api';

export const path = '/';
export const exact = true;

export const component = function Home() {
  return (
    <HasRole role={ROLES.Hanketoteuttaja} fallback={<Forbidden />}>
      <NotFound />
    </HasRole>
  );
};
