import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMUILocale from '@visma/react-app-locale-utils/lib/useMUILocale';
import { useMemo } from 'react';
import './main.css';
import { customConfig } from './config';
import { MuiAccordionSummary } from './overrides/MuiAccordion';
import MuiAppBar from './overrides/MuiAppBar';
import MuiDialog from './overrides/MuiDialog';
import MuiTooltip from './overrides/MuiTooltip';
import * as overrides from './overrides';

export default function Theme({ children }) {
  const locale = useMUILocale();

  let theme = customConfig(useMemo(() => createTheme({}, locale), [locale]));

  theme = createTheme(theme, {
    components: {
      MuiAccordionSummary: MuiAccordionSummary(theme),
      MuiAppBar: MuiAppBar(theme),
      MuiDialog: MuiDialog(theme),
      MuiTooltip: MuiTooltip(theme),
      ...overrides,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
