export const ROLES = {
  Hvo: 'HVO',
  Hanketoteuttaja: 'HANKETOTEUTTAJA',
};

export const REPORTS = {
  Hvo: 'hankkeet_HVO',
  Sfc: 'sfc-report_group',
  HankeSfc: 'hanke_SFC',
  Osallistuja: 'osallistujan_tiedot',
  HankeIndikaattorit: 'hanke_indikaattorit',
  HankkeenOsallistujat: 'hankkeen_osallistujat',
};
