import eu_flag from '../images/eu_flag.png';
import eu_flag_small from '../images/eu_flag_small.png';

const MuiAppBar = ({ breakpoints, spacing }) => ({
  styleOverrides: {
    root: {
      backgroundImage: `url(${eu_flag})`,
      backgroundSize: 'cover',
      [breakpoints.down('sm')]: {
        backgroundImage: `url(${eu_flag_small})`,
      },
      '& .MuiToolbar-regular': {
        minHeight: spacing(10),
      },
      '& .imageContainer': {
        marginRight: spacing(3),
        display: 'flex',
        alignItems: 'center',
        '& img': {
          marginRight: spacing(3),
        },
        [breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '& .headerContainer': {
        marginRight: spacing(3),
        [breakpoints.down('sm')]: {
          marginLeft: spacing(3),
        },
        [breakpoints.down('xs')]: {
          order: 2,
          textAlign: 'center',
          margin: `${spacing(2)}px auto`,
        },
      },
      '& .loginContainer': {
        display: 'flex',
        [breakpoints.up('sm')]: {
          position: 'absolute',
          right: spacing(3),
          top: spacing(1),
        },
        [breakpoints.down('xs')]: {
          width: '100%',
          order: 1,
          justifyContent: 'center',
          marginTop: spacing(1),
          '& >div': {
            justifyContent: 'center',
          },
        },
      },
      '& .instructionContainer': {
        [breakpoints.up('sm')]: {
          position: 'absolute',
          right: spacing(3),
          bottom: spacing(1),
        },
        [breakpoints.down('xs')]: {
          width: '100%',
          order: 3,
          marginBottom: spacing(1),
          textAlign: 'center',
        },
        '& a': {
          color: 'inherit',
          textDecoration: 'none',
        },
        '& a+a': {
          marginLeft: spacing(3),
        },
      },
    },
  },
});

export default MuiAppBar;
