const MuiTooltip = ({ palette, typography, ...theme }) => ({
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.secondary.light,
      fontSize: typography.fontSize,
      color: palette.common.black,
    },
  },
});

export default MuiTooltip;
