import { useFormState } from 'react-use-form-state';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import messages from '@esrh/app/src/messages';
import { styled } from '@mui/material/styles';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  '& .MuiFormControl-root': {
    marginBottom: theme.spacing(1),
  },
  '& .MuiGrid-item': {
    paddingBottom: theme.spacing(1),
  },
  '& .no-padding': {
    paddingTop: 0,
    paddingBottom: theme.spacing(3),
  },
}));

const RadioLegend = styled(FormLabel)(({ theme }) => ({
  color: 'black',
  marginBottom: theme.spacing(1),
}));

const Field = ({ id, ...props }) => (
  <TextField
    id={id || props.name}
    {...props}
    variant="outlined"
    fullWidth
    size="small"
  />
);

export default function Search({ hankeId, handleSearchData }) {
  const [formState, { text, radio, checkbox }] = useFormState({
    makuloitu: 'false',
  });
  const intl = useIntl();
  const label = (name) => intl.formatMessage(messages[name]);

  const { trackEvent } = useMatomo();

  const trackEventToMatomo = () => {
    trackEvent({
      category: 'search',
      action: 'search-participants',
    });
  };

  return (
    <StyledForm
      onSubmit={async (event) => {
        event.preventDefault();
        const data = { hankeId };
        for (const [key, value] of Object.entries(formState.values)) {
          if (value !== '' && value !== undefined) data[key] = value;
        }
        handleSearchData(data);
      }}
    >
      <Grid container spacing={6}>
        <Grid item md={6} sm={12}>
          <Field label={label('etunimi')} {...text('etunimi')} />
          <Field label={label('sukunimi')} {...text('sukunimi')} />
          <Field label={label('hetu')} {...text('hetu')} />
          {hankeId ? (
            <Field label={label('postinumero')} {...text('postinumero')} />
          ) : (
            <Field label={label('hankeId')} {...text('hankeId')} />
          )}
        </Grid>
        <Grid item md={3} sm={12}>
          <FormControl component="fieldset">
            <RadioLegend component="legend">
              <FormattedMessage {...messages.sukupuoli} />
            </RadioLegend>
            <RadioGroup aria-label="gender" name="customized-radios">
              <FormControlLabel
                {...radio('sukupuoli', undefined)}
                value={undefined}
                control={<Radio color="primary" />}
                label={label('notSelected')}
              />
              <FormControlLabel
                {...radio('sukupuoli', 1)}
                value={1}
                label={label('male')}
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                {...radio('sukupuoli', 2)}
                value={2}
                control={<Radio color="primary" />}
                label={label('female')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {hankeId && (
          <Grid item md={3} sm={12}>
            <FormControl component="fieldset" style={{ display: 'block' }}>
              <RadioLegend component="legend">
                <FormattedMessage {...messages.state} />
              </RadioLegend>
              <RadioGroup>
                <FormControlLabel
                  {...radio('makuloitu', 'false')}
                  value={'false'}
                  control={<Radio color="primary" />}
                  label={label('active')}
                />
                <FormControlLabel
                  {...radio('makuloitu', 'true')}
                  value={'true'}
                  control={<Radio color="primary" />}
                  label={label('removed')}
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={'MuiTypography-srOnly'}>
                <FormattedMessage
                  defaultMessage={'Tallennettu aloitus- tai lopetusilmoitus'}
                />
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...checkbox('aloitusilmoitus')}
                      color="primary"
                    />
                  }
                  label={label('aloitusilmoitus')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...checkbox('lopetusilmoitus')}
                      color="primary"
                    />
                  }
                  label={label('lopetusilmoitus')}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} className={'no-padding'}>
          <Button
            onClick={trackEventToMatomo}
            variant="contained"
            color="primary"
            type={'submit'}
          >
            <FormattedMessage {...messages.searchParticipants} />
          </Button>
        </Grid>
      </Grid>
    </StyledForm>
  );
}
