import { format, isBefore, isFuture, isValid, parse } from 'date-fns';
import { useIntl } from 'react-intl';
import messages from 'messages';
import validator from 'email-validator';
import { FinnishBusinessIds } from 'finnish-business-ids';

export const parseDate = (value) => {
  if (value !== '') {
    const year = value
      ? value.substring(value.lastIndexOf('.') + 1, value.length)
      : '';
    return year.length === 4
      ? parse(value, 'dd.MM.yyyy', new Date())
      : year.length === 2 && parse(value, 'dd.MM.yy', new Date());
  } else {
    return null;
  }
};

export const useDateValidator = (props) => {
  const intl = useIntl();
  return (value) => {
    if (props?.notRequired && value.length === 0) return undefined;
    const result = parseDate(value);
    const afterDate = props?.notAfter && parseDate(props.notAfter);
    if (!isValid(result)) {
      return intl.formatMessage(messages.incorrectDate);
    } else if (props && props.notInFuture && isFuture(result)) {
      return intl.formatMessage(messages.dateInFuture);
    } else if (
      props?.notBefore &&
      isBefore(result, new Date(props.notBefore))
    ) {
      return intl.formatMessage(
        props.isHankeDate ? messages.dateBeforeHanke : messages.dateBefore,
        {
          date: format(new Date(props.notBefore), 'd.M.yyyy'),
        }
      );
    } else if (afterDate && isBefore(afterDate, result)) {
      return intl.formatMessage(messages.dateAfter, {
        date: format(afterDate, 'd.M.yyyy'),
      });
    }
  };
};

export const useZipValidator = () => {
  const intl = useIntl();
  return (value) => {
    if (value.length !== 5 || !Number(value))
      return intl.formatMessage({
        defaultMessage: 'Virheellinen postinumero.',
      });
  };
};

export const useHetuValidator = () => {
  const intl = useIntl();
  return (value, values) => {
    const n = parseInt(values.hetuEnd.slice(0, -1));
    const hasHetu = values.hetuEnd.length > 0;
    if (
      hasHetu &&
      (!n || (values.hetuEnd.length !== 4 && values.hetuEnd.length > 0))
    ) {
      return intl.formatMessage({
        defaultMessage:
          'Virheellinen henkilötunnus. Henkilötunnus tulee olla muodossa XXXX.',
      });
    } else if (hasHetu && values.sukupuoli) {
      const hetuSukupuoli = n % 2 === 0 ? '2' : '1';
      if (hetuSukupuoli !== values.sukupuoli) {
        return intl.formatMessage({
          defaultMessage: 'Virhe. Henkilötunnus ja sukupuoli eivät täsmää.',
        });
      }
    }
  };
};

export const useEmailValidator = () => {
  const intl = useIntl();
  return (value) => {
    if (value.length > 0 && !validator.validate(value)) {
      return intl.formatMessage({
        defaultMessage: 'Virheellinen sähköpostiosoite.',
      });
    }
  };
};

export const useBusinessIdValidator = () => {
  const intl = useIntl();
  return (value) => {
    if (value.length > 0 && !FinnishBusinessIds.isValidBusinessId(value)) {
      return intl.formatMessage({
        defaultMessage: 'Virheellinen y-tunnus.',
      });
    }
  };
};
