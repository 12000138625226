import { create as createUseAxios } from 'use-axios';
import { create as createAxios } from 'axios';
import config from '@visma/public.config';
import { isEmpty } from 'lodash';

export const axios = createAxios({
  baseURL: config.backend.baseURL,
});

const { useAxiosSafe } = createUseAxios(axios);
export const clientPromise = axios;

const executeOnce = (fn) => {
  let running;
  return function (...args) {
    if (!running) {
      running = fn.apply(this, args);
      (async () => {
        await running;
        running = null;
      })();
    }
    return running;
  };
};

//AUTENTIKOINTI
export const postAuthentication = executeOnce(
  async function postAuthentication({ hanke }) {
    try {
      const response = await axios.post('/public/authenticate', hanke);
      sessionStorage.setItem('Esrh-Authorization', `Bearer ${response.data}`);
    } catch (error) {
      return { error: error || true };
    }
  }
);

//HANKE
export function useHanke() {
  if (!axios.defaults.headers['Esrh-Authorization'])
    axios.defaults.headers['Esrh-Authorization'] =
      sessionStorage.getItem('Esrh-Authorization');
  const [error, result] = useAxiosSafe(`/hanke`);
  return error || !result.data ? { error: error || true } : result.data;
}

export function useHankeById(id) {
  const [error, result] = useAxiosSafe(`/hanke/hvo/${id}`);
  return error || isEmpty(result.data) || !result.data
    ? { error: error || true }
    : result.data;
}

export function useHankes() {
  const [error, result] = useAxiosSafe('/hanke/list');
  return error ? { error: error || true } : result.data;
}

export function useEsrhCodes() {
  const [error, result] = useAxiosSafe('/koodit');
  return error ? { error: error || true } : result.data;
}

//OSALLISTUJA
export function useOsallistujaByHanke(data) {
  const [error, result] = useAxiosSafe(`/osallistuja/search-pageable`, {
    params: data,
  });
  return error ? { error: error || true } : result.data;
}

export async function getOsallistuja(osallistujaId) {
  return await axios
    .get(`/osallistuja/summary/${osallistujaId}`)
    .then(({ data }) => ({ ...data.osallistujaDetails, ...data }))
    .catch((error) => ({ error: error || true }));
}

export async function postOsallistuja({ osallistuja, override }) {
  return await axios
    .post(`/osallistuja${override ? '?override=true' : ''}`, osallistuja)
    .then(({ data }) => data)
    .catch((error) => {
      try {
        return { error: error.response.status };
      } catch (e) {
        return { error: error || true };
      }
    });
}

export async function editOsallistuja({ osallistuja }) {
  return await axios
    .post('/osallistuja/update', osallistuja)
    .then(async ({ data }) => ({
      editedOsallistuja: await getOsallistuja(data.osallistujaId),
    }))
    .catch((error) => ({ error: error || true }));
}

export async function deleteOsallistuja(osallistujaId) {
  await axios
    .delete(`/osallistuja/${osallistujaId}`)
    .catch((error) => ({ error: error || true }));
}

export function HasRole({ role, fallback, children }) {
  const [error, response] = useAxiosSafe(`/public/has-role/ROLE_${role}`);
  return error || !response.data ? fallback : children;
}

//ALOITUSILMOITUS
export async function getAloitusilmoitus(osallistujaId) {
  return await axios
    .get(`/aloitusilmoitus/${osallistujaId}`)
    .then(({ data: aloitusilmoitus }) => ({ aloitusilmoitus }))
    .catch((error) => ({ error: error || true }));
}

export async function postAloitusilmoitus(aloitusilmoitus) {
  return await axios
    .post('/aloitusilmoitus', aloitusilmoitus)
    .then(async () => await getAloitusilmoitus(aloitusilmoitus.osallistujaId))
    .catch((error) => ({ error: error || true }));
}

export async function deleteAloitusilmoitus({ aloitusId, osallistujaId }) {
  return await axios
    .delete(`/aloitusilmoitus/${aloitusId}`)
    .then(async () => await getAloitusilmoitus(osallistujaId))
    .catch(() => false);
}

//LOPETUSILMOITUS
export async function getLopetusilmoitus(osallistujaId) {
  return await axios
    .get(`/lopetusilmoitus/${osallistujaId}`)
    .then(({ data: lopetusilmoitus }) => ({ lopetusilmoitus }))
    .catch((error) => ({ error: error || true }));
}

export async function postLopetusilmoitus(lopetusilmoitus) {
  return await axios
    .post('/lopetusilmoitus', lopetusilmoitus)
    .then(async () => await getLopetusilmoitus(lopetusilmoitus.osallistujaId))
    .catch((error) => ({ error: error || true }));
}

export async function deleteLopetusilmoitus({ lopetusId, osallistujaId }) {
  return await axios
    .delete(`/lopetusilmoitus/${lopetusId}`)
    .then(async () => await getLopetusilmoitus(osallistujaId))
    .catch((error) => ({ error: error || true }));
}

//RAPORTIT
export async function getReport(props) {
  return await axios
    .post(`/reports`, props)
    .then((a) => a.data)
    .catch((error) => ({ error: error || true }));
}
