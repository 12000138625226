import useLocale from '@visma/react-app-locale-utils/lib/useLocale';
import { Typography } from '@mui/material';
import Page from '../../../layouts/Page';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HankeSelection from './HankeSelection';
import Search from './Search';
import HankeReport from './HankeReport';
import { REPORTS } from '../../../../util';
import LoadButton from './LoadButton';
import { path as parent } from '..';
import { styled } from '@mui/material/styles';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const path = `${parent}/etusivu`;
export const exact = true;

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.dark,
  borderBottom: `1px solid ${theme.palette.primary.dark}`,
}));

export const component = function Frontpage() {
  const [locale] = useLocale();
  const [loading, setLoading] = useState([]);
  const defaults = {
    locale,
    loading,
    setLoading,
    format: 'xls',
    contained: true,
  };

  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'ESR-Henkilö',
      href: window.location,
    });
  });

  return (
    <Page>
      <div>
        <Title component="h2" variant="h6" style={{ marginTop: 0 }}>
          <FormattedMessage defaultMessage={'Hanke valinta'} />
        </Title>
        <HankeSelection />
      </div>
      <div>
        <Title component="h2" variant="h6">
          <FormattedMessage defaultMessage={'Osallistujien haku hankkeista'} />
        </Title>
        <Search />
      </div>
      <div>
        <Title component="h2" variant="h6">
          <FormattedMessage
            defaultMessage={'Hallintoviranomaisen hankeraportti'}
          />
        </Title>
        <HankeReport defaults={defaults} />
      </div>
      <div>
        <Title component="h2" variant="h6">
          <FormattedMessage defaultMessage={'SFC-raportti'} />
        </Title>
        <LoadButton {...defaults} report={REPORTS.Sfc} />
      </div>
      <div>
        <Title component="h2" variant="h6">
          <FormattedMessage defaultMessage={'Hankekohtainen SFC-raportti'} />
        </Title>
        <LoadButton {...defaults} report={REPORTS.HankeSfc} />
      </div>
    </Page>
  );
};
