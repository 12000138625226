import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SearchResults from './SearchResults';
import UserSearch from '@esrh/core/src/ESRH/User/Search';
import { getOsallistuja } from 'api';
import { getUserWithHetu } from '@esrh/core/src/ESRH/User/utils';
import { path as hanke } from './Hanke';
import { styled } from '@mui/material/styles';

const Hint = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export default function Search() {
  const history = useHistory();
  const [searchData, setSearchData] = useState();

  const onOpen = async (osallistujaId) => {
    const user = await getOsallistuja(osallistujaId);
    history.push({
      pathname: generatePath(hanke, { hankeId: user.hankeId }),
      state: { user: getUserWithHetu({ user }) },
    });
  };

  return (
    <div>
      <Typography>
        <FormattedMessage
          defaultMessage={'Osallistujien hakeminen hankkeista'}
        />
      </Typography>
      <Hint className={'bottomMargin'}>
        <FormattedMessage defaultMessage={'Valitse ensin hakuehdot'} />
      </Hint>
      <UserSearch handleSearchData={setSearchData} />
      {searchData && <SearchResults data={searchData} onOpenUser={onOpen} />}
    </div>
  );
}
