import React, { useState } from 'react';
import SearchResults from './SearchResults';
import Search from './Search';

export default function SearchContainer({ hankeId, onOpen }) {
  const [searchData, setSearchData] = useState();

  return (
    <div>
      <Search handleSearchData={setSearchData} hankeId={hankeId} />
      {searchData && <SearchResults data={searchData} onOpen={onOpen} />}
    </div>
  );
}
