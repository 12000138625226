import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';
import LayoutFallback from '../LayoutFallback';
import BackButton from '../BackButton';

export default function NotFound() {
  return (
    <LayoutFallback>
      <Alert severity="info">
        <FormattedMessage defaultMessage="Sivua ei löydy" />
      </Alert>
      <BackButton />
    </LayoutFallback>
  );
}
