import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

export default function ConfirmDialog({
  label = 'vahvista',
  content,
  open,
  onClose,
  onConfirm,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage {...messages[label]} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage {...messages[content]} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          <FormattedMessage {...messages.vahvista} />
        </Button>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          <FormattedMessage {...messages.peruuta} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
