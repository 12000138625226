import loadRuntimeConfig from '@visma/public.config/lib/loadRuntimeConfig.js';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

async function main() {
  await loadRuntimeConfig(
    process.env.PUBLIC_URL + process.env.REACT_APP_RUNTIME_CONFIG_PUBLIC_URL
  );

  const App = (await import(/* webpackMode: "eager" */ 'components/App'))
    .default;

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

main();
