import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { TableHeader } from './TableHeader';
import { useOsallistujaByHanke } from 'api';
import Pagination from './Pagination';
import messages from '../../../app/src/messages';
import { getComparator, stableSort } from './utils';
import { styled } from '@mui/material/styles';

const PaginationWrapper = styled(TablePagination)(({ theme }) => ({
  marginTop: theme.spacing(4),
  '& .MuiTablePagination-toolbar': {
    background: theme.palette.action.hover,
  },
  '& .MuiTypography-root:nth-of-type(2)': {
    flex: 2,
    textAlign: 'center',
  },
}));
const Wrapper = styled('div')(({ theme }) => ({
  '& .MuiTablePagination-toolbar': {
    paddingLeft: theme.spacing(2),
  },
}));

const TableRoot = styled(TableBody)(({ theme }) => ({
  '& .MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& button': {
    padding: 0,
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'initial',
    },
  },
}));
const PaddedAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const useEffects = ({ setPage, data }) => {
  useEffect(() => {
    const links = document.getElementById('main-links');
    if (links) links.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [data, setPage]);
};

const isBoolean = (v) => !!v === v;
const getBoolean = (v) =>
  v ? (
    <FormattedMessage {...messages.yes} />
  ) : (
    <FormattedMessage {...messages.no} />
  );

export default function SortableTable({ data, rowBuilder, headCells, onOpen }) {
  const sorter = headCells[0].id.toLowerCase();
  const [order, setOrder] = useState('asc');
  const [sortBy, setSortBy] = useState(sorter);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  useEffects({ setPage, data });
  const ids = headCells.map((c) => c.id);
  const users = useOsallistujaByHanke({
    ...data,
    page,
    size,
    sort: `${sortBy},${order}`,
  });
  const rows = users.error ? [] : users.content.map((u) => rowBuilder(u));

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangeRowsPerPage = ({ target }) => {
    setSize(parseInt(target.value, 10));
    setPage(0);
  };

  return rows.length > 0 ? (
    <Wrapper>
      <PaginationWrapper
        ActionsComponent={Pagination}
        rowsPerPageOptions={[20, 40, 60, 100]}
        component="div"
        count={users.totalElements}
        rowsPerPage={size}
        page={page}
        onPageChange={(e, page) => setPage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
      />
      <TableContainer>
        <Table size={'small'}>
          <TableHeader
            headCells={headCells}
            order={order}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableRoot>
            {stableSort(rows, getComparator(order, sortBy)).map((row) => (
              <TableRow hover key={row.id}>
                {ids.map((key) => (
                  <TableCell key={row.id + key}>
                    {onOpen && (row[key] || isBoolean(row[key])) ? (
                      <Button onClick={() => onOpen(row.id)} color="primary">
                        {isBoolean(row[key]) ? getBoolean(row[key]) : row[key]}
                      </Button>
                    ) : isBoolean(row[key]) ? (
                      getBoolean(row[key])
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableRoot>
        </Table>
      </TableContainer>
    </Wrapper>
  ) : (
    <PaddedAlert
      elevation={6}
      variant="filled"
      severity={users.error ? 'error' : 'info'}
    >
      {users.error ? (
        <FormattedMessage
          defaultMessage={'Järjestelmävirhe. Ole hyvä ja päivitä sivu.'}
        />
      ) : (
        <FormattedMessage
          defaultMessage={'Hakuehdoilla ei löytynyt osallistujia'}
        />
      )}
    </PaddedAlert>
  );
}
