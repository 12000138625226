import Alert from '@mui/material/Alert';
import { Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomAlert({ severity, isOpen, setOpen, children }) {
  return (
    <Collapse in={isOpen}>
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setOpen(undefined)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {children}
      </Alert>
    </Collapse>
  );
}
