import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@esrh/app/src/messages';
import { postAloitusilmoitus } from '@esrh/app/src/api';
import {
  FormField,
  getOptions,
  koulutusForm,
  TyokykyField,
  tyokykyForm,
  workingId,
} from './util';
import { Button } from '@mui/material';
import common from '@esrh/messages/src/main';
import {
  parseDate,
  useDateValidator,
} from '@esrh/core/src/FormUtil/Validators';
import CustomAlert from '@esrh/core/src/Alert/CustomAlert';
import { SideBySideField } from '@esrh/core/src/FormUtil/Fields';
import { styled } from '@mui/material/styles';
import { rootStyle, selectStyle } from '../../FormUtil/Styles';

const StyledForm = styled('form')(({ theme }) => ({
  ...rootStyle(theme),
  ...selectStyle(theme),
}));

const InnerTable = styled('div')(() => ({
  marginLeft: 60,
  '& .MuiFormControlLabel-label': {
    marginRight: -60,
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: 'flex',
  justifyContent: 'space-between',
}));

const getKeyValues = (data) => ({
  tyo: { key: 'tyollisyysTilanne', value: data.tyollisyystilanneAloitusList },
  tyonantaja: { key: 'tyonantaja', value: data.tyonantajaList },
  ammattiluokka: { key: 'ammattiluokka', value: data.ammattiluokkaList },
  opo: {
    key: 'ohjauspalvelunAsiantuntija',
    value: data.opintoohjaajaAloitusList,
  },
  koulutus: { key: 'koulutusTaso', value: data.koulutusasteAloitusList },
  ulkomaalainen: {
    key: 'muutUlkomaalainen',
    value: data.muutTaustatekijatAloitusList,
  },
  vammainen: { key: 'muutVammainen', value: data.muutTaustatekijatAloitusList },
  heikkous: { key: 'muutHeikkous', value: data.muutTaustatekijatAloitusList },
  tyokyky: { key: 'tyokyky', value: data.arvioList },
});

export default function AloitusilmoitusForm({
  erityistavoite,
  osallistujaId,
  hankeAloitusDate,
  data,
  onClose,
}) {
  const intl = useIntl();
  const [formState, { text }] = useFormState();
  const [openAlert, setOpenAlert] = useState(false);
  const showTyokyky = tyokykyForm.includes(erityistavoite);
  const showOpo = koulutusForm.includes(erityistavoite);
  const isWorking = formState.values.tyollisyysTilanne === workingId;
  const keyValues = getKeyValues(data);

  const defaultValues = ({ key, value }) => ({
    className: 'formControl',
    label: intl.formatMessage(messages[key]),
    errors: formState.errors,
    options: getOptions(value),
  });

  return (
    <StyledForm
      onSubmit={async (event) => {
        event.preventDefault();
        if (Object.values(formState.validity).some((val) => !val)) {
          setOpenAlert(true);
        } else {
          const aloitusPvm = parseDate(formState.values.aloitusPvm);
          await postAloitusilmoitus({
            osallistujaId,
            ...formState.values,
            aloitusPvm,
          }).then((ilmoitus) => onClose({ ilmoitus }));
        }
      }}
    >
      <CustomAlert severity={'error'} isOpen={openAlert} setOpen={setOpenAlert}>
        <FormattedMessage {...messages.formError} />
      </CustomAlert>
      <SideBySideField
        required
        errors={formState.errors}
        {...text({
          name: 'aloitusPvm',
          validate: useDateValidator({
            notBefore: hankeAloitusDate,
            isHankeDate: true,
          }),
          validateOnBlur: !formState.errors.aloitusPvm,
        })}
      />
      <FormField
        required={true}
        {...defaultValues(keyValues.tyo)}
        {...text(keyValues.tyo.key)}
      />
      {isWorking && (
        <InnerTable>
          <FormField
            required={false}
            {...defaultValues(keyValues.tyonantaja)}
            {...text(keyValues.tyonantaja.key)}
          />
          <FormField
            required={false}
            {...defaultValues(keyValues.ammattiluokka)}
            {...text(keyValues.ammattiluokka.key)}
          />
          {showOpo && (
            <FormField
              {...defaultValues(keyValues.opo)}
              {...text(keyValues.opo.key)}
            />
          )}
        </InnerTable>
      )}
      <FormField
        required={true}
        {...defaultValues(keyValues.koulutus)}
        {...text(keyValues.koulutus.key)}
      />
      <FormField
        required={true}
        {...defaultValues(keyValues.ulkomaalainen)}
        {...text(keyValues.ulkomaalainen.key)}
      />
      <FormField
        required={true}
        {...defaultValues(keyValues.vammainen)}
        {...text(keyValues.vammainen.key)}
      />
      <FormField
        required={true}
        {...defaultValues(keyValues.heikkous)}
        {...text(keyValues.heikkous.key)}
      />
      {showTyokyky && (
        <TyokykyField
          placeholder={intl.formatMessage(messages.notSelected)}
          label={intl.formatMessage(messages.tyokyky)}
          className={'formControl'}
          {...text('tyokyky')}
        />
      )}
      <ButtonContainer>
        <Button variant="contained" color="primary" type={'submit'}>
          <FormattedMessage {...common.submit} />
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          <FormattedMessage {...common.cancel} />
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
}
