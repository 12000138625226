import React from 'react';
import { useIntl } from 'react-intl';
import messages from '@esrh/app/src/messages';
import Info from '../User/Info';
import SearchContainer from '../User/SearchContainer';
import Form from '../User/Form';
import { PANEL } from './index';
import CustomAccordion from '../../CustomAccordion';

const getHeading = (panel) => {
  return panel === PANEL.info
    ? 'selectedUserInfo'
    : panel === PANEL.search
    ? 'searchParticipants'
    : 'basicInfo';
};

export default function ActionPanel({
  canEdit,
  action,
  expanded,
  handleChange,
  user,
  setUser,
  hankeId,
  onOpen,
  onRemoved,
  onEdit,
}) {
  const intl = useIntl();

  return (
    <CustomAccordion
      id={action}
      isExpanded={expanded.includes(action)}
      handleChange={handleChange}
      heading={intl.formatMessage(messages[getHeading(action)])}
    >
      {action === PANEL.user ||
      action === PANEL.addAloitusilmoitus ||
      action === PANEL.addLopetusilmoitus ? (
        <Info user={user} canEdit={canEdit} onEdit={onEdit} />
      ) : action === PANEL.search ? (
        <SearchContainer hankeId={hankeId} onOpen={onOpen} />
      ) : (
        <Form
          hankeId={hankeId}
          hasSubmitted={setUser}
          onUserRemoved={onRemoved}
          userInfo={
            action === PANEL.edit
              ? {
                  ...user,
                  hetuEnd: user.hetuEnd || '',
                  sukupuoli: user.sukupuoli && user.sukupuoli.toString(),
                }
              : false
          }
        />
      )}
    </CustomAccordion>
  );
}
