import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, Collapse, Typography } from '@mui/material';

const useAlertTimer = ({ isOpen, setOpen }) => {
  useEffect(() => {
    if (isOpen) {
      const timeId = setTimeout(() => {
        setOpen(false);
      }, 3000);
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [isOpen, setOpen]);
};

export default function WithTimer({
  severity,
  style,
  isOpen,
  setOpen,
  children,
}) {
  useAlertTimer({ isOpen, setOpen });

  const inputEl = useRef(null);
  const scroll = () =>
    inputEl.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  if (inputEl.current && isOpen) scroll();

  useEffect(() => {
    if (inputEl.current && isOpen) scroll();
  }, [isOpen]);

  return (
    <Collapse in={isOpen}>
      <Alert
        ref={inputEl}
        severity={severity}
        style={{ marginBottom: 30, scrollMargin: 10, ...style }}
        onClose={() => setOpen(false)}
      >
        {severity === 'warning' && (
          <AlertTitle>
            <FormattedMessage defaultMessage="Jotain meni pieleen" />
          </AlertTitle>
        )}
        <Typography>{children}</Typography>
      </Alert>
    </Collapse>
  );
}
