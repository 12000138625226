import { useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  NativeSelect,
  TextField,
} from '@mui/material';
import messages from 'messages';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';

export const SelectField = ({ component, required, label, children }) => (
  <FormControl required={required} component={component} fullWidth>
    <FormControlLabel
      label={`${label} ${required ? '*' : ''}`}
      labelPlacement="start"
      control={children}
    />
  </FormControl>
);

export const Selector = ({ options, props }) => {
  const intl = useIntl();

  return (
    <NativeSelect {...props}>
      <option value="" disabled>
        {intl.formatMessage({ defaultMessage: 'Ei valittu' })}
      </option>
      {options.map(({ label, value }) => (
        <option key={label} value={value}>
          {intl.formatMessage(messages[label])}
        </option>
      ))}
    </NativeSelect>
  );
};

export const BasicField = ({ id, ...props }) => (
  <TextField
    {...props}
    id={id || props.name}
    variant="outlined"
    fullWidth
    size="small"
    error={!!props.errors[props.name]}
    helperText={props.errors[props.name]}
  />
);

const BasicInput = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '& fieldset': {
    top: 0,
  },
  '& legend': {
    display: 'none',
  },
  '& label': {
    transform: 'none',
    transition: 'none',
    position: 'initial',
  },
  '& .MuiFormHelperText-root': {
    flexBasis: '100%',
    marginLeft: '50%',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginTop: 0,
  },
  '& .MuiFormLabel-root': {
    paddingRight: theme.spacing(3),
  },
}));

export const SideBySideField = ({ errors, id, ...props }) => {
  const intl = useIntl();

  return (
    <BasicInput
      {...props}
      id={id || props.name}
      size="small"
      label={intl.formatMessage(messages[props.name])}
      error={errors && !!errors[props.name]}
      helperText={errors && errors[props.name]}
      errors={errors}
    />
  );
};

export const getAdornment = () => ({
  endAdornment: (
    <InputAdornment position={'end'}>
      <LockIcon />
    </InputAdornment>
  ),
});
