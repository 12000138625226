import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const RootCell = styled(TableCell)(({ theme }) => ({
  '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active':
    {
      '&:hover': {
        color: theme.palette.text.secondary,
      },
      '& .MuiTableSortLabel-icon': {
        color: 'black',
      },
    },
}));

const NoSort = styled('span')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 18,
    marginRight: 0,
    color: theme.palette.text.secondary,
  },
}));

const LastIcon = styled(ArrowDropDown)(() => ({
  verticalAlign: 'bottom',
  marginLeft: -18,
}));

export function TableHeader({ headCells, order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <RootCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                hideSortIcon={true}
                IconComponent={ArrowDropUp}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy !== headCell.id && (
                  <NoSort>
                    <ArrowDropUp />
                    <LastIcon />
                  </NoSort>
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </RootCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
