import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import common from '@esrh/messages/src/main';
import messages from '@esrh/app/src/messages';
import BoxLayout, { LinkBox } from '@esrh/core/src/BoxLayout';
import LoadButton from '@esrh/app/src/components/Routes/Hvo/Frontpage/LoadButton';
import { REPORTS } from '@esrh/app/src/util';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale';

const getUserInfo = (user) => [
  {
    etunimi: user.etunimi,
    sukunimi: user.sukunimi,
    sukupuoli: user.sukupuoli,
    syntymaPaiva: user.syntymaPaiva,
  },
  {
    katuosoite: user.katuosoite,
    postinumero: user.postinumero,
    hetu: user.hetu,
  },
  {
    postitoimipaikka: user.postitoimipaikka,
    puhelinnumero: user.puhelinnumero,
    sposti: user.sposti,
  },
];

export default function Info({ user, canEdit, onEdit }) {
  const intl = useIntl();
  const [loading, setLoading] = useState([]);
  const [locale] = useLocale();
  const userInfo = getUserInfo({
    ...user,
    sukupuoli:
      user.sukupuoli === 1
        ? intl.formatMessage(messages.male)
        : user.sukupuoli === 2 && intl.formatMessage(messages.female),
  });

  return (
    <BoxLayout dataArray={userInfo} labelWidth={150}>
      <LinkBox>
        {canEdit && (
          <div>
            <Button onClick={onEdit} color="primary" className={'as-link'}>
              <FormattedMessage {...common.edit} />
            </Button>
          </div>
        )}
        <div>
          <LoadButton
            report={REPORTS.Osallistuja}
            format={'pdf'}
            loading={loading}
            setLoading={setLoading}
            locale={locale}
            osallistujaId={user.osallistujaId}
            hankeId={user.hankeId}
          />
        </div>
      </LinkBox>
    </BoxLayout>
  );
}
