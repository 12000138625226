import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import messages from 'messages';
import { styled } from '@mui/material/styles';

const FlexContainer = styled(Box)(({ theme }) => ({
  flexWrap: 'wrap',
  width: '100%',
  padding: 0,
  overflow: 'hidden',
  [theme.breakpoints.down('xs')]: {
    display: 'block',
  },
  '& .MuiBox-root': {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    '& > div': {
      paddingBottom: theme.spacing(1),
    },
  },
}));

const GridHeader = styled(Typography)(({ theme }) => {
  return {
    fontWeight: 'bold',
    display: 'inline-block',
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  };
});

const GridContent = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    display: 'inline-block',
    maxWidth: 'calc(100% - 150px)',
  },
}));

export const LinkBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function BoxLayout({ dataArray, labelWidth, children }) {
  return (
    <FlexContainer display="flex" p={1}>
      {dataArray.map((box, index) => (
        <Box flexGrow={2} key={`box_${index}`}>
          {Object.entries(box).map(([label, value]) => (
            <div className={'rowContainer'} key={`${label}_field`}>
              <GridHeader style={{ width: labelWidth }}>
                <FormattedMessage {...messages[label]} />
              </GridHeader>
              <GridContent>{value}</GridContent>
            </div>
          ))}
        </Box>
      ))}
      {children}
    </FlexContainer>
  );
}
