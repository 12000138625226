const MuiButton = {
  styleOverrides: {
    root: {
      minHeight: 40,
      minWidth: 150,
      '&.as-link': {
        textAlign: 'left',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        '& span': {
          fontSize: 'inherit',
        },
      },
    },
    label: {
      textTransform: 'none',
      fontSize: 'medium',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      lineHeight: 'initial',
      textAlign: 'left',
    },
  },
};

export default MuiButton;
