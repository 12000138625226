import { FormattedMessage } from 'react-intl';
import { Grid, Table, TableBody, TableRow, Tooltip } from '@mui/material';
import {
  koulutusForm,
  StyledCell,
  StyledRemoveButton,
  StyledTable,
  tyokykyForm,
  useGetStartData,
} from './util';
import messages from '@esrh/app/src/messages';
import { useState } from 'react';
import { deleteAloitusilmoitus } from '@esrh/app/src/api';
import ConfirmDialog from '@esrh/core/src/ConfirmDialog';

export default function Aloitusilmoitus({
  aloitusilmoitus,
  erityistavoite,
  onDeleted,
  data,
  ...props
}) {
  const [confirmRemove, setConfirmRemove] = useState(false);
  const showTyokyky = tyokykyForm.includes(erityistavoite);
  const showOpo = koulutusForm.includes(erityistavoite);
  const rows = useGetStartData({ aloitusilmoitus, showOpo, data });
  const onRemove = async () => {
    await deleteAloitusilmoitus(aloitusilmoitus).then((ilmoitus) => {
      setConfirmRemove(false);
      onDeleted({ ilmoitus });
    });
  };

  return (
    <>
      <ConfirmDialog
        content={'deleteAloitusilmoitus'}
        open={confirmRemove}
        onConfirm={onRemove}
        onClose={() => setConfirmRemove(false)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <StyledTable>
            <Table aria-label="aloitus table">
              <colgroup>
                <col style={{ width: '75%' }} />
                <col />
              </colgroup>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={`row_${index}`}>
                    <StyledCell component="th" scope="row">
                      {row.label}
                    </StyledCell>
                    <StyledCell colSpan={2}>{row.value}</StyledCell>
                  </TableRow>
                ))}
                {showTyokyky && (
                  <TableRow key={`row_tyokyky`}>
                    <StyledCell component="th" scope="row">
                      <FormattedMessage {...messages.tyokyky} />
                    </StyledCell>
                    <StyledCell colSpan={2}>
                      {aloitusilmoitus.tyokyky}
                    </StyledCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTable>
        </Grid>
        {props.canEdit && (
          <Grid item xs={12} md={3} style={{ textAlign: 'right' }}>
            {props.hasLopetusilmoitus ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <FormattedMessage
                    defaultMessage={
                      'Käyttäjällä on aktiivisia lopetusilmoituksia. Poistaaksesi ' +
                      'aloitusilmoituksen, poista ensin kaikki aktiiviset lopetusilmoitukset'
                    }
                  />
                }
              >
                <span>
                  <StyledRemoveButton
                    setConfirmRemove={setConfirmRemove}
                    disabled={true}
                  />
                </span>
              </Tooltip>
            ) : (
              <StyledRemoveButton setConfirmRemove={setConfirmRemove} />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
